import React from 'react';

import Slider from 'react-slick';
import Image1 from '../../assets/images/about/1.jpg';
import Image2 from '../../assets/images/about/2.jpg';
import Image3 from '../../assets/images/about/3.jpg';
import Image4 from '../../assets/images/about/4.jpg';
import Image5 from '../../assets/images/about/5.jpg';
import Image6 from '../../assets/images/about/6.jpg';
import Image7 from '../../assets/images/about/7.jpg';
import Image8 from '../../assets/images/about/8.jpg';
import Image9 from '../../assets/images/about/9.jpg';
import Image10 from '../../assets/images/about/10.jpg';
import Image11 from '../../assets/images/about/11.jpg';
import Image12 from '../../assets/images/about/12.jpg';
import Image13 from '../../assets/images/about/13.jpg';
import Image14 from '../../assets/images/about/14.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

let imgArr = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10,
  Image11,
  Image12,
  Image13,
  Image14
];

const settings = {
  className: 'center',
  centerMode: true,
  dots: true,
  infinite: true,
  autoplay: true,
  centerPadding: '24px',
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 500,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
export default function AboutSlider() {
  return (
    <div className='About-new__Slider'>
      <Slider {...settings}>
        {imgArr.map((img, index) => {
          return (
            <div key={index}>
              <img src={img} alt='' />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
