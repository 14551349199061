import React from 'react';
import InstagramIcon from '../../assets/icons/instagram.svg';
import LinkedinIcon from '../../assets/icons/linkedin.svg';
import TwitterIcon from '../../assets/icons/twitter.svg';
import ArtistImg from '../../assets/images/artists/artist.jpeg';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';

import './Artist.scss';
import { motion } from 'framer-motion';
import { paragraphAnimation } from '../../utils/config';

function Artist() {
  return (
    <section id='artists' className='Artists container'>
      <AnimateTextInView className='Title' type='h1' text='Supreme Kong Artist' />
      <div className='Artists__content'>
        <motion.div
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          variants={{
            visible: { opacity: 1, scale: 1, y: 0 },
            hidden: { opacity: 0, scale: 0.5, y: 150 },
          }}
          className='Artists__img'
        >
          <img src={ArtistImg} alt='' />
        </motion.div>
        <div className='Artists__about'>
          <AnimateTextInView text='HOSSEIN DIBA' type='h3' />
          <motion.p className='section__p' {...paragraphAnimation}>
            With over 300,000 followers on social media, Hossein Diba is a
            character and creature artist specializing in 3D modelling, and
            digital sculpting. His work is famously respected across the gaming,
            collectable, and cinematic worlds.
          </motion.p>
          <motion.p className='section__p' {...paragraphAnimation}>
            Hossein Diba has won couple of awards including sculpt of the year
            award and have worked with the biggest company of the industry as
            Activision, Blizzard and video game as Tomb Raider.
          </motion.p>
          <motion.p className='section__p' {...paragraphAnimation}>
            For over 15 years he has combined his detailed knowledge of making
            the 3D part of the movies with his immense passion for creating
            cutting-edge and award-winning digital art of the highest quality.
            By bringing that elite artistry into the Metaverse, be ready to
            accept the envy and respect you will naturally attract.
          </motion.p>
          <motion.ul
            {...paragraphAnimation}
            className='flex Artists__about__social'
          >
            <li>
              <a
                href='https://www.linkedin.com/in/hossein-diba-75308170'
                target='_blank'
                rel='noreferrer'
              >
                <img src={LinkedinIcon} alt='' />
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/hosseindibaart?s=11'
                target='_blank'
                rel='noreferrer'
              >
                <img src={TwitterIcon} alt='' />
              </a>
            </li>
          </motion.ul>
        </div>
      </div>
    </section>
  );
}

export default Artist;
