import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Banner from '../../components/BannerJngl/Banner';
import Header from '../../components/HeaderJngl/Header';
import About from '../../components/Junglenomic/About';
import Htb from '../../components/Instruction/About';
import Map from '../../components/Junglemap/About';
import FAQ from '../../components/FAQ/FAQ';
import Footer from '../../components/Footer/Footer';
import Artist from '../../components/Artist/Artist';
import RoadMap from '../../components/RoadMap/RoadMap';
import Features from '../../components/Features/Features';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Preloader from '../../components/Preloader/Preloader';
import FeaturesSlider from '../../components/Features/FeaturesSlider/FeaturesSlider';
import './jngl.scss';

import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';


function Home() {

  const [loaded, setLoaded] = React.useState(false)

  const handleLoad = React.useMemo(() => {
    setLoaded(true)
  }, []);

  return (
    <div className="body-jngl">
      <Preloader handleLoad={handleLoad} />
      <Header siteTitle={`Title`} />
      <Banner/>
      <About />
      <Htb />
      <Map />
      <footer className="footer">
          <div className="footer__section">
            <p className="footer__copyright">Copyright © 2023 $JNGL Token. All Rights Reserved.</p>
          </div>
          <div className="footer__section"> 
           <a href='https://etherscan.io/token/0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
            <a href='https://discord.com/invite/supremekong'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
            <a href='https://twitter.com/Supremekongnft'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
            <a href='https://opensea.io/collection/supremekong'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xcd02a882804f3eb0f2d80f94a04defcea038359f'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
            <a href='mailto:cyrus@supremekong.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
          </div>
      </footer>
    </div>
  );
}

export default Home;
