import React, { useEffect, useRef } from 'react';

const EntryList = ({ entries, onLoadMore }) => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      if (
        container.scrollHeight - container.scrollTop === container.clientHeight
      ) {
        // User has reached the bottom of the list, load more entries
        onLoadMore();
      }
    };

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [onLoadMore]);

  return (
    <div className="entry-list-container" ref={containerRef}>
      <table className="entry-list-table">
        <thead>
          <tr>
            <th>Entry ID</th>
            <th>Participant</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry, index) => (
            <tr key={index}>
              <td>{index+1}</td>
              <td>{entry.participant}</td>
              <td>Entry</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EntryList;
