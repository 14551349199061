import { motion } from 'framer-motion';
import React , {useEffect} from 'react';
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import Header from '../../components/HeaderJngl/Header';
import { useNavigate } from 'react-router-dom';

import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';

import banner from '../../assets/images/staking-banner.png'
import logo from '../../assets/images/staking-logo.png'

import token1 from '../../assets/images/token-kong.png'
import token2 from '../../assets/images/token-db.png'

import liveWin from '../../assets/images/live_win.svg'
import winnerCard from '../../assets/images/winner-card.png'

import './MP.scss';
import { useWeb3Context } from '../../context'

import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'

const marketplaceAbi = require('../../helper/marketplace.json')
const raffleAbi = require('../../helper/raffle.json')
const stakingAbi = require('../../helper/stakingOffchain.json')
const alphaAbi = require('../../helper/alpha.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function Home() {
  const navigate = useNavigate();

  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  const [kongBalance, setKongBalance] = React.useState(0)
  const [kongBalanceRaw, setKongBalanceRaw] = React.useState(0)

  const [showModal, setShowModal] = React.useState(false)
  const [showUnstakeModal, setShowUnstakeModal] = React.useState(false)

  const stakingAddress = "0xdf88AEa19eB0bdc40135e6C15bc9AAD9b06Ed40F"

  const [stakeAmount, setStakeAmount] = React.useState(0)
  const [unstakeAmount, setUnstakeAmount] = React.useState(0)

  const [stakingBalance, setStakingBalance] = React.useState(0)
  const [earnedBalance, setEarnedBalance] = React.useState(0)
  const [stakingBalanceRaw, setStakingBalanceRaw] = React.useState(0)

  const [stakeMax, setStakeMax] = React.useState(0)

  const marketplaceAddress = "0x1f2eF28F3e36838fea994e1Be564be9E5993E0e2"

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  const [Listing, setListing] = React.useState([]);
  const base_uri = "https://api.supremekong.com";

  useEffect(async() => {
    //Runs only on the first render
    if(web3Provider){
      console.log('web3Provider')
      // loadToken()
      loadListing()
    }else{
    }
  }, [web3Provider]);

  const [selectedImage, setSelectedImage] = React.useState();

  const handleItemClick = (item) => {
    setSelectedImage(item.image); // You may need to adjust this line depending on your data structure
  };

  function handleRedirect(id) {
    navigate('/jungle/raffle/'+id);
  }

  const loadToken = async() => {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract("0xa985512fb9e26A5FC72788335567769Ec96CD131", alphaAbi, signer);
    
    const stakingContract = new ethers.Contract(stakingAddress, stakingAbi, signer);
    
    const balance = await contract.balanceOf(address);
    const stakingBalance = await stakingContract.getStaked(address, 0);
    const earned = await stakingContract.earned(address, 0);

    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.ceil(balanceInEther);
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0});

    const stakingBalanceInEther = ethers.utils.formatEther(stakingBalance);
    const roundedStakingBalanceInEther = Math.ceil(stakingBalanceInEther);
    const formattedStakingBalance = roundedStakingBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0});

    const earnedInEther = ethers.utils.formatEther(earned);
    const formattedEarned = Math.abs(earnedInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits:2});
    
    setKongBalanceRaw(roundedBalanceInEther)
    setStakeMax(roundedBalanceInEther)
    setKongBalance(formattedBalance);

    setStakingBalanceRaw(roundedStakingBalanceInEther)
    setStakingBalance(formattedStakingBalance);
    setUnstakeAmount(roundedStakingBalanceInEther)

    setEarnedBalance(formattedEarned);
  }

  const loadListing = async() => {
    try{
      setListing([])

      const query = `
        {
          poolCreateds(first: 100) {
            id
            poolId
          }
        }
        `;
      const listing = await axios.post('https://api.studio.thegraph.com/query/46966/kong-raffle-goerli/version/latest', {query: query})

      let listingData = listing.data.data.poolCreateds;

      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, raffleAbi, signer);
      
      const listingPromises = listingData.map(async (listing) => {
        try {
          const listingInfo = await contract.getPool(listing.poolId);
      
          const priceInEther = ethers.utils.formatEther(listingInfo[5]);
          listing.name = listingInfo[0]
          listing.priceDisplay = parseFloat(priceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
          listing.end = listingInfo[9];
          listing.image = "https://marketplace.alphashards.com/images/"+listing.collectionId+".avif"
      
          return listing;
        } catch (error) {
          console.error(`Error processing listing ${listing.poolId}:`, error);
          return null;
        }
      });

      // Wait for all metadata requests to complete and filter out any null results
      //const duplicatedData = Array.from({length: 12}, () => [...listingData]).flat();
      listingData = await Promise.all(listingPromises);
      setListing(listingData)
    } catch(err) {
      console.log(err)
    }
  }


  const claim = async () => {

    try{
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(stakingAddress, stakingAbi, signer);

      const tx = await contract.claimRewards(0)
      await tx.wait()
      toast.success('Transaction Success')

      
      let res = await axios.post(base_uri + '/grantRewardJngl', {}, 
      {
        headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m'
        }
      })
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }


  return (
    <>
    <Header siteTitle={`Title`}/>
    <div
    id='Home' className='Raffle'>
      <div className='Raffle__content'>          
        <div className='marketplace-flex-container'>
          

          <div className='raffle-banner-container'>
            <div style={{display:'flex'}}>
              <div className='marketplace-text-container-top'>
                  $JNGL RAFFLE
              </div>
              {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                  Your balance : <img ></img> {alphaBalance}
              </div> */}
            </div>
            <div style={{display:'flex'}}>
              <div className='raffle-text-container-bottom'>
                  Win, win, win!
              </div>
              {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                  Your balance : <img ></img> {alphaBalance}
              </div> */}
            </div>
          </div>

          <div style={{display:'flex'}}>
            <div className='raffle-text-container-bottom-title' style={{alignSelf:'flex-end', fontSize:'24px',marginTop:'5px',marginLeft:'10px'}}>
              <img src={liveWin}></img>
            </div>
            {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                Your balance : <img ></img> {alphaBalance}
            </div> */}
          </div>

          <div className="raffle-win-container">
            {/* {Listing.map((item, index) => (
              <div key={index} className="raffle-winner-item">
                <img src={winnerCard}></img>
              </div>
            ))} */}
          </div>

          <div style={{display:'flex'}} className="raffle-content-container">
            <div className='raffle-text-container-bottom-title' style={{alignSelf:'flex-end'}}>
              SUPREME RAFFLE
            </div>
            {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                Your balance : <img ></img> {alphaBalance}
            </div> */}
          </div>

          <div className='raffle-items-container'>
            { Listing.map( (item) => (
              <div className='raffle-item-card' onClick={() => handleItemClick(item)}>
                <div className='raffle-item-card-image'>
                  <img src={item.image}></img>
                  
                </div>

                <div className='raffle-item-card-info'>

                  <div className='raffle-item-card-info-title'>
                    <p >{item.name}</p> 
                  </div>

                  <div className='raffle-item-card-info-price'>
                    <p > {item.priceDisplay} $JNGL</p>
                  </div>

                  <div className='raffle-item-card-info-button'>
                      <div className='raffle-item-card-info-button-buy' onClick={()=> {handleRedirect(item.poolId)}}>
                        ENTER
                      </div>
                  </div>

                  <div className='raffle-item-card-info-price'>
                    <p > Entries : 0 </p>
                  </div>

                  

                </div>
              </div>
            ))}
          </div>

          
          

        </div>
      </div>
    </div>
    <footer className="footer">
        <div className="footer__section">
          <p className="footer__copyright">Copyright © 2023 $JNGL Token. All Rights Reserved.</p>
        </div>
        <div className="footer__section"> 
         <a href='https://etherscan.io/token/0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
          <a href='https://discord.com/invite/supremekong'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
          <a href='https://twitter.com/Supremekongnft'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
          <a href='https://opensea.io/collection/supremekong'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
          <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xcd02a882804f3eb0f2d80f94a04defcea038359f'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
          <a href='mailto:cyrus@supremekong.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
        </div>
    </footer>
    </>
  );
}
