import React from 'react';

import TwitterIcon from '../../assets/icons/twitter.svg';
import InstagramIcon from '../../assets/icons/instagram.svg';

import './Footer.scss';
function Footer() {
  return (
    <footer className='Footer'>
      <div className='flex fac Footer__social'>
        <a href='https://twitter.com/Supremekongnft' target='_blank' rel='noreferrer'>
          <img src={TwitterIcon} alt='' />
        </a>
      </div>
      <p>©All rights reserved. {new Date().getFullYear()}</p>
    </footer>
  );
}

export default Footer;
