import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { paragraphAnimation } from "../../utils/config";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import token from "../../assets/images/jngl-token.png";

import Header from "../../components/HeaderJngl/Header";

import "./Vesting.scss";
import { toast } from "react-toastify";

import nftAbi from "../../helper/stakeAbi.json";
import kongRedeemAbi from "../../helper/kongRedeem.json";
import jungleRewards3Abi from "../../helper/jungleRewards3Abi.json"
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useWeb3Context } from "../../context";
import axios from "axios";

import { useNavigate, useParams } from "react-router-dom";

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
};

if (typeof window !== "undefined") {
  web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true,
    providerOptions, // required
  });

  console.log(web3Modal);
}

export default function Mint() {
  let navigate = useNavigate();
  const tokens = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${tokens}`;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  const { web3Provider, connect, disconnect, address } = useWeb3Context();
  //const address = "0x047862412AF18dA4c549549630887dbA1AF6C0F2"
  const [mintVisible, setMintVisible] = React.useState(true);

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // );

  const [eligibleAmount, setEligibleAmount] = React.useState(0);

  const [unreleasedAmount, setUnreleasedAmount] = React.useState(0);

  const [releasedAmount, setReleasedAmount] = React.useState(0);

  const [totalAmount, setTotalAmount] = React.useState(0);

  const [kongOwned, setKongOwned] = React.useState(0);

  const [timer, setTimer] = React.useState("00:00:00");

  const redeemContractAddress = "0x22d138e08f3AbA618A34300Bc0caCf16D6dC1014";

  const kongContractAddress = "0x12787526c03d626AAc88E6EDc4d0Fb930d86C631";

  const jungleRewards3Address = "0x01928041D4E9A72cbCaA566167462d470609d0BC";

  const stakignContractAddress = "0x70C7af7a78B5453E4A09376eB2b506CE4E4140E5";

  useEffect(async () => {
    //Runs only on the first render
    if (web3Provider) {
      console.log("check1");
      await loadAmount();
      await loadTimer();
    } else {
    }
  }, [web3Provider]);

  const redirectStaking = async () => {
    navigate("/jngl/staking");
  };

  const handleClaimTokens = async () => {
    try {
      if (!web3Provider) {
        toast.error("Please connect to a wallet first");
        return;
      }
      const signer = web3Provider.getSigner();
      const jungleRewards3 = new ethers.Contract(
        jungleRewards3Address,
        jungleRewards3Abi,
        signer
      );

      // Get the required ETH amount

      // Initiate claim tokens transaction
      const claimTx = await jungleRewards3.claimRewards(0);
      await claimTx.wait();

      toast.success("Claiming Succesfull");

      //do a hard refresh
      setTimeout(async () => {
        await loadAmount();
        await loadTimer();
      }, 2000); // 2 seconds (2000 milliseconds) delay
    } catch (err) {
      toast.error("Claiming FAILED");
    }
  };

  function formatCountdown(timestamp) {
    const cutoffDate = Math.floor(new Date("2024-08-06T00:00:00Z").getTime() / 1000); // Fixed cutoff date in seconds
    const oneWeekInSeconds = 7 * 24 * 3600; // 1 week in seconds
    const targetTimestamp = timestamp + oneWeekInSeconds; // Calculate 1 week after the claimed timestamp

    const timeDifference = targetTimestamp - cutoffDate; // Calculate the difference in seconds

    if (timeDifference <= 0) {
      return "00:00:00:00"; // Handle the case when the target time has already passed
    }

    const days = Math.floor(timeDifference / 86400); // 86400 seconds in a day
    const hours = Math.floor((timeDifference % 86400) / 3600); // 3600 seconds in an hour
    const minutes = Math.floor((timeDifference % 3600) / 60); // 60 seconds in a minute
    const seconds = timeDifference % 60;

    const formattedTime = `${String(days).padStart(2, "0")}:${String(
      hours
    ).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}`;

    return formattedTime;
  }

  const loadTimer = async () => {
    console.log("called");
    try {
      const signer = web3Provider.getSigner();
      const kongRedeem = new ethers.Contract(
        redeemContractAddress,
        kongRedeemAbi,
        signer
      );

      const nftcontract = new ethers.Contract(
        stakignContractAddress,
        nftAbi,
        signer
      );

      const stakedKong = await nftcontract.stakedNfts(address, 0);

      if (stakedKong.length == 0) {
        return;
      }

      let timestamp = await kongRedeem.userLastClaimTimestamp(stakedKong[0], 0);
      console.log("staked1", stakedKong);

      console.log("timestamp", timestamp.toNumber());

      timestamp = timestamp.toNumber();

      const updateCountdown = () => {
        const countdown = formatCountdown(timestamp);
        setTimer(countdown);

        if (timestamp < 0) {
          clearInterval(interval); // Stop the countdown when it reaches 00:00:00:00
        }
      };

      // Initial update
      updateCountdown();

      // Set up the interval to update the countdown every second
      const interval = setInterval(updateCountdown, 1000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(interval);
    } catch (error) {
      console.log(error);
    }
  };

  // replace with junglereward3.sol
  const loadAmount = async () => {
    console.log("calling loadAmount");
    try {
      const signer = web3Provider.getSigner();
      const nftcontract = new ethers.Contract(
        stakignContractAddress,
        nftAbi,
        signer
      );
      console.log("init nftContract");
      const jungleRewards3 = new ethers.Contract(
        jungleRewards3Address,
        jungleRewards3Abi,
        signer
      );
      console.log("init jungleRewards3");


      const stakedKong = await nftcontract.stakedNfts(address, 0);
console.log("get stakedKong");
console.log(stakedKong);
      const amount = stakedKong.length;
console.log(amount);
      const lastClaimInNewContract = await jungleRewards3.userLastClaimInNewContract(address, 0);
console.log("======");
console.log(lastClaimInNewContract);
console.log(lastClaimInNewContract.toNumber());
console.log("======");
      const hasClaimed = lastClaimInNewContract.toNumber() !== 0;

      if (hasClaimed) {
        setTotalAmount("0.00")
      } else {

      const eligible = await jungleRewards3.simulateYield(0, address);

      const yieldInEth = ethers.utils.formatEther(eligible);

      const roundedBalanceInEther = Math.abs(yieldInEth);
      const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      setTotalAmount(formattedBalance);
    }

      setKongOwned(amount.toString());
    } catch (error) {
      console.log(`Err on loadAmount(): ${error}`)
    }
  };

  return (
    <>
      <Header siteTitle={`Title`} />
      <motion.section {...paragraphAnimation} id="Vesting" className="Vesting">
        <div className="Vesting__content">
          <div className="flex-vesting-container">
            <div className="flex-item-left" style={{ color: "white" }}>
              <div className="flex-item-left-title">
                <div className="claim-text-container">CLAIM</div>

                <div className="alpha-text-container">$JNGL</div>
              </div>

              <div className="flex-item-right-title">
                <div className="claim-text-container">FROM STAKING SK1</div>
              </div>
            </div>

            <div className="flex-vesting-left" style={{ color: "white" }}>
              <div className="wolfvesting-text-container">
                Total SUPREME KONG NFT
              </div>

              <div className="address-text-container" style={{ width: "100%" }}>
                {kongOwned}
              </div>

              <div className="wolfvesting-text-container">Claimable $JNGL</div>

              <div className="address-text-container" style={{ width: "100%" }}>
                {totalAmount}
              </div>

              <div style={{ display: "flex", float: "right" }}>
                <button
                  className="vesting-stake-button-claim"
                  onClick={redirectStaking}
                >
                  Stake $JNGL
                </button>
                <button
                  className="vesting-button-claim"
                  onClick={handleClaimTokens}
                >
                  Claim
                </button>
              </div>

              <div
                className="wolfvesting-text-container"
                style={{ width: "100%", fontSize: "16px", paddingTop: "10px" }}
              >
                {timer}
              </div>
            </div>
          </div>
        </div>
      </motion.section>
    </>
  );
}
