import { motion } from 'framer-motion';
import React from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';

import './About.scss';
import AboutSlider from './AboutSlider';

function About() {
  return (
    <div id='about' className='About-new'>
      <div className='container'>
        {/* <motion.h1 {...paragraphAnimation} className='About__title Title'>
          UNQUESTIONED DOMINANCE FINALLY UNLEASHED
        </motion.h1> */}
        <div className='flex fwrap fjc '>
          <AnimateTextInView className='Title' text='SUPREME' type='h1' />
          <h1 className='YellowTitle'>DOMINANCE </h1>
          <AnimateTextInView className='Title' text='UNDENIABLE' type='h1' />
          <AnimateTextInView className='Title' text='IN THE JUNGLE' type='h1' />
        </div>
        <div>
          <motion.p
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 40 },
            }}
            className='section__p'
          >
            Everything once known is forgotten.
            The constructs of what is the golden standard in NFTs is redefined.
            Supreme Kong launched in February 2022 leading the way for
            projects to follow: building Web 3 the right way - community first.
          </motion.p>
          <motion.p
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 40 },
            }}
            className='section__p'
          >
            Designed with its holders in mind, our genesis collection launched
            with a series of 2000 Supreme Kong limited and powerful pieces
            available on the Ethereum blockchain. Our mission is to drive value
            not only to our holders, instead to be a launchpad for all creatives,
            entrepreneurs, developers in the space to come build on top of
            Supreme Kong. With IP rights, all holders of any Jungle Labz tokens
            are allowed to commercially monetize their assets without limitations.
            In tandem with support from our team, we are here building a thriving
            wider ecosystem outside of just the SK family. We have our own
            currency, $KONG and $JNGL power our ecosystem; earnable by staking our NFTs.
          </motion.p>
          <motion.p
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 40 },
            }}
            className='section__p'
          >
            We built the world's first multi-chain staking rewards marketplace -
            now every holder can exchange $KONG for Jungle Labz tokens,
            ETH and SOL NFTs. Feel your chest beating, dive deeper in the jungle.
          </motion.p>
        </div>
      </div>
      {/* <AboutSlider /> */}
    </div>
  );
}

export default About;
