import * as React from "react"

import './Mutate.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import axios from "axios";
import arrow from '../../assets/images/arrow.png'
import ProgressBar from "../../components/Progressbar/Progressbars";
import { fail } from "assert";
import { HmacSHA256, enc } from 'crypto-js';
import mutantbanana from '../../assets/images/supreme-bananas.jpeg'

const mutantbananaAbi = require('../../helper/mutantBanana.json')
const stakingAbi = require('../../helper/stakeAbi.json')
const bananaAbi = require('../../helper/bananaAbi.json')

let interval;

function Mint() {
    const tokens = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokens}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    let navigate = useNavigate()

    let { id, type } = useParams();

    console.log(type)

    //const base_uri = "http://localhost:8001";
    const base_uri = "https://api.supremekong.com";


    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
    const stakingContract = process.env.REACT_APP_STAKING_CONTRACT;

    const burnCost = 5;

    const arrayCosts = [1,2,3,5,6,8,10,12,14,18]

    const arrayBonus = ["0%","2%","4%","6%","8%","10%","15%","25%","40%","65%","100%"]

    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const [loaded, setLoaded] = React.useState(false)

    const [owner, setOwner] = React.useState(false)

    const [potion, setPotion] = React.useState(0)

    const [load, setLoad] = React.useState(false)

    const [level, setlevel] = React.useState('')

    const [chance, setChance] = React.useState(0)

    const [eligible, setEligible] = React.useState(true)

    const [maxAmount, setMaxAmount] = React.useState(0)

    const [amount, setAmount] = React.useState(0);

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [mintVisible, setMintVisible] = React.useState(true);

    const countDownDate = new Date("2022-08-31T22:00:00.000-04:00").getTime();
    const [showModal, setShowModal] = React.useState(true);

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    const [bananaNft, setbananaNft] = React.useState([])

    const [leveling, setLeveling] = React.useState(false)

    const [failed, setFailed] = React.useState(false)

    const [success, setSuccess] = React.useState(false)

    const [progress, setProgress] = React.useState(false)

    const [progressRate, setProgressRate] = React.useState(0)

    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)


    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        if(type == "supreme-mutant-banana"){
            getMutantMetadata()
        }

        if(type == "supreme-kong"){
            getKongMetadata()
        }

        if(type == "supreme-kong-2"){
            getGen2Metadata()
        }

        if(type == "supreme-banana"){
            getBananaMetadata()
        }
    }, [web3Provider])

    const getMutantMetadata = async() => {
        const res = await axios.get(base_uri+'/getLevel?id='+id,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        let metadata = res.data
        let levels = metadata.level
        setlevel(levels)
    }

    const getBananaMetadata = async() => {
        const res = await axios.get(base_uri+'/getLevelSupremeBanana?id='+id,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        let metadata = res.data
        let levels = metadata.level
        
        setlevel(levels)
    }

    const getGen2Metadata = async() => {
        const res = await axios.get(base_uri+'/getLevelGen2s3?id='+id,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        console.log("res",res.data)
        let metadata = res.data
        let levels = metadata.level
        
        setlevel(levels)
    }

    const getKongMetadata = async() => {
        const res = await axios.get(base_uri+'/getLevelKong?id='+id,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        let metadata = res.data
        console.log('meta',metadata)
        let levels = metadata.level
        console.log('level',levels)
        
        setlevel(levels)
    }


    return (
        <>
        <Preloader handleLoad={handleLoad} />
        <Header siteTitle={`Title`} />
        <div className='Mint__content'>
        
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
        { !leveling &&
            <>
                <div className='level-container'>
                    <p>CHECK LEVEL</p>
                    { type == 'supreme-mutant-banana' &&
                        <p style={{fontSize:"14px",marginTop:"15px"}}>Supreme Mutant Bananas #{id}</p>
                    }

                    { type == 'supreme-kong' &&
                        <p style={{fontSize:"14px",marginTop:"15px"}}>Supreme Kong #{id}</p>
                    }

                    { type == 'supreme-kong-2' &&
                        <p style={{fontSize:"14px",marginTop:"15px"}}>Supreme Kong 2 #{id}</p>
                    }

                    { type == 'supreme-banana' &&
                        <p style={{fontSize:"14px",marginTop:"15px"}}>Supreme Banana #{id}</p>
                    }
                
                <div className="level-change">
                    { type == 'supreme-mutant-banana' &&
                        <div className="level-before">
                            <video autoPlay loop playsInline controls muted src="https://assets.supremekong.com/mutant-bananas/images/mutant-banana.mp4" ></video>
                            Level {level}
                        </div>
                    }

                    { type == 'supreme-kong' &&
                        <div className="level-before">
                            <img src={"https://raffle.supremekong.com/images/"+id+".png"}></img>
                            Level {level}
                        </div>
                    }

                    { type == 'supreme-kong-2' &&
                        <div className="level-before">
                            <img src={"https://gen2.supremekong.com/images/"+id+".jpg"}></img>
                            Level {level}
                        </div>
                    }

                    { type == 'supreme-banana' &&
                        <div className="level-before">
                            <img src={mutantbanana}></img>
                            Level {level}
                        </div>
                    }

                </div>


                
                
                </div>
            </>
        }
      </div>
        </>
    );
}

export default Mint;
