import { motion } from 'framer-motion';
import React from 'react';
import { paragraphAnimation } from '../../utils/config';
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import Kong from '../../assets/images/skBanner.png'


import './Banner.scss';

export default function Banner() {
  return (
    <motion.section 
    {...paragraphAnimation}
    id='banner' className='Banner-jngl'>
      <div className='Banner-jngl__content'>
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Banner__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
        {/* <div className='mainTitle'>
          <div className='smallTitle'>
            SUPREME
          </div>
          <div className='bigTitle'>
            KONG
          </div>
        </div>
        <div className='flex'>
          <div className='DiscordButton-new'>
            Buy on OpenSea
          </div>

          <p className='desc'>Supreme Collection of Jungle Labz.</p>
        </div> */}
        <div className='section-left'>
          <div className='section-left__title'>
            <div className='medTitle'>
              $JNGL
            </div>
            <div className='supersmallTitle'>
              <p style={{display: 'inline'}}>Powers </p>
              <p style={{display: 'inline', color:'rgba(68, 255, 80, 1)', marginLeft: '5px'}}>Supreme Kong</p>
              <p style={{display: 'inline', marginLeft: '5px'}}> gamified ecosystem.</p>
            </div>
          </div>
          <div className='section-left__desc'>
            <p>

            </p>
          </div>
          <div className='section-button'>
          </div>
        </div>
        <div className='section-right'>
          <img src={Kong}></img>
        </div>
      </div>
    </motion.section>
  );
}
