import * as React from "react"

import {useState} from "react"

import './Mutate.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import axios from "axios";
import arrow from '../../assets/images/arrow.png'
import ProgressBar from "../../components/Progressbar/Progressbars";
import { fail } from "assert";
import { HmacSHA256, enc } from 'crypto-js';
import mutantbanana from '../../assets/images/supreme-bananas.jpeg'

const mutantbananaAbi = require('../../helper/mutantBanana.json')
const stakingAbi = require('../../helper/stakeAbi.json')
const bananaAbi = require('../../helper/bananaAbi.json')

let interval;

function Mint() {
    const tokens = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokens}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    let navigate = useNavigate()


    //const base_uri = "http://localhost:8001";
    const base_uri = "https://api.supremekong.com";


    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
    const stakingContract = process.env.REACT_APP_STAKING_CONTRACT;

    const burnCost = 5;

    const arrayCosts = [1,2,3,5,6,8,10,12,14,18]

    const arrayBonus = ["0%","2%","4%","6%","8%","10%","15%","25%","40%","65%","100%"]

    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const [loaded, setLoaded] = React.useState(false)

    const [owner, setOwner] = React.useState(false)

    const [potion, setPotion] = React.useState(0)

    const [load, setLoad] = React.useState(false)

    const [level, setlevel] = React.useState(0)

    const [chance, setChance] = React.useState(0)

    const [eligible, setEligible] = React.useState(true)

    const [maxAmount, setMaxAmount] = React.useState(0)

    const [amount, setAmount] = React.useState(0);

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [mintVisible, setMintVisible] = React.useState(true);

    const countDownDate = new Date("2022-08-31T22:00:00.000-04:00").getTime();
    const [showModal, setShowModal] = React.useState(true);

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    const [bananaNft, setbananaNft] = React.useState([])

    const [leveling, setLeveling] = React.useState(false)

    const [failed, setFailed] = React.useState(false)

    const [success, setSuccess] = React.useState(false)

    const [progress, setProgress] = React.useState(false)

    const [progressRate, setProgressRate] = React.useState(0)

    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)

    const [selectedDropdownValue, setSelectedDropdownValue] = useState("supreme-kong"); // Initialize with an empty string
    const [inputFieldValue, setInputFieldValue] = useState(0); // Initialize with an empty string

    // Function to handle changes in the dropdown
    const handleDropdownChange = (event) => {
        setSelectedDropdownValue(event.target.value); // Update the selectedDropdownValue state with the selected value
    };

    // Function to handle changes in the input field
    const handleInputChange = (event) => {
        setInputFieldValue(event.target.value); // Update the inputFieldValue state with the input value
    };

    const checkLevel = (event) => {
        navigate('/check-level/'+selectedDropdownValue+"/"+inputFieldValue)
    };


    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        //getMutantMetadata()
    }, [web3Provider])

    // const getMutantMetadata = async() => {
    //     const res = await axios.get(base_uri+'/getLevel?id='+id,
    //     {
    //     headers: {
    //         api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
    //     }
    //     })
    //     let metadata = res.data
    //     let levels = metadata.level
    //     let chances = arrayCosts[levels]
    //     const resp = await axios.get(base_uri+'/getClaimedPrize?address='+ address +'&id='+2,
    //     {
    //     headers: {
    //         api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
    //     }
    //     })
    //     let potions = resp.data.claimed
    //     console.log(resp.data)
    //     setPotion(resp.data.claimed)
    //     setlevel(levels)
    //     setChance(chances)
    //     console.log(chances)
    //     console.log(potions)
    //     if(chances > potions){
    //         console.log("chance")
    //         setEligible(false)
    //     }else{
    //         setEligible(true)
    //     }
    // }



    return (
        <>
        <Preloader handleLoad={handleLoad} />
        <Header siteTitle={`Title`} />
        <div className='Mint__content'>
        
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
        { !leveling &&
            <>
                <div className='level-container'>
                    <p>CHECK LEVEL</p>
                    <div className="level-change-dropdown" >
                        <select className="level-dropdown" onChange={handleDropdownChange}>
                            <option value="supreme-kong">Supreme Kong</option>
                            <option value="supreme-kong-2">Supreme Kong 2</option>
                            <option value="supreme-mutant-banana">Supreme Mutant Banana</option>
                            <option value="supreme-banana">Supreme Banana</option>
                        </select>
                        <input type="number" min={0} className="level-dropdown" onChange={handleInputChange} placeholder="Enter Token ID"></input>
                    </div>

                    <div className="level-button">
                        <div className='mintBtn' onClick={checkLevel}>
                            CHECK
                        </div>
                    </div>
                </div>
            </>
        }
      </div>
        </>
    );
}

export default Mint;
