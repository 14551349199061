import React from 'react';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import RoadMapCard from './RoadMapCard/RoadMapCard';

import smokeVideo from '../../assets/video/smoke.mp4';

import './RoadMap.scss';
const roadMapCards = [
  {
    title: 'MAKING HISTORY',
    content: `We are the first project in the world to be completely unrugged and also offering multi-chain staking rewards. Through launches, we've given over $400K for free to holders. All collections in profit, 97%+ delisted, & thriving, with 50%+ staked.`,
  },
  {
    title: 'PROJECT PARTNERS & WEB3 VISIBILITY',
    content: `Over last 7 months we have built partnerships with Degen Toonz, Apes Together Strong, Ape Reunion, Genuine Undead, GodHatesNFTees and many others. Our emphasis on building, cross-pollinating, maintaining, and elevating these relationships are paramount to the success we have seen to date and will continue to fuel future success. We will be forging new relationships while strengthening our existing ecosystem. Our most recent examples was launch of Titan Radio, where now Titan and Cyrus are hosting the 4th largest radio show in NFTs. Another example our space b/w Toonz x Undead x Kongs.`,
  },
  {
    title: 'GEN 2 KONGS',
    content: `Gen 2 Kong collection will be launched end of this year. The supply & mint price is TBD. To be able to FREE MINT Gen 2 , you need any Kong + Banana pairing. You are not limited. 10 sets = 10 free Gen 2 Kong claims. Our Gen 2 Kong collection does not have a name yet, but it will soon. All of our art is being co-created with the community. This will be our 4th collection launch this year, and the 1st Kong collection under our new team.`,
  },
  {
    title: 'SUPREME & MUTANT BANANAS',
    content: `Things are about to get crazy for the Supreme and Mutant Bananas. Our Supreme Bananas are our most affordable entry into our ecosystem, whereas our Mutants are the most premium entry into our project. If you want to experience our leveling system before Gen 2 Kongs launch, you will need to get your hands on a Mutant Supreme Banana. For all our Supreme Banana holders, listen carefully. You are are the backbone and critical component to our entire ecosystem. Anyone that wants to get in on the Kong ecosystem and is priced out on Kongs, will enter through Supreme Bananas. As we keep adding more affordable NFTs to our marketplace, easily claimable with Supreme Banana yields, the adoption and valuations of Supreme Bananas will continuously steady increase every month. Nobody in the market has a ~.11 ETH (current price of Supreme Bananas) asset that yields them or provides them the access, claimable, or experience that our tokens do. In just under 1 month, there has been about 100 ETH traded on Supreme Bananas. Where will next few months take us? As people need bananas for Gen 2 claim & beyond?`,
  },
  {
    title: `STAKING ADVANCEMENTS`,
    content: `We will be continuously tweaking yield rates and what tokens can be stake-able. The possibilities are endless for staking. New mechanisms will be added to keep you surprised. From dashboards to visibility on more metrics for you to optimize your yield and experience. The name of the game is how high can you get your daily / weekly $KONG yield rate. This will determine how much ETH and SOL you can earn per week or per month. For those tight on liquidity, it's not about how fast you can earn, but what you can save up for.`,
  },
  {
    title: 'MARKETPLACE ADVANCEMENTS',
    content: `Our marketplace will go through a lot of upgrades this year. We wont reveal everything to you as we want to leave room for speculation but here is a little to tease: Auto Redeem Claiming, Live Marketplace Pricing, Leveling system, Inventory bags, Expansion of ETH and SOL offerings, and OTC marketplace. There are many more upgrades on the development pipe, you'll have to wait to see.`,
  },
];

function RoadMap() {
  return (
    <div id='roadmap' className='RoadMap'>
      <section className='RoadMap__content container'>
        <AnimateTextInView className='Title' type='h1' text='RoadMap 3.0' />
        <div className='RoadMap__content'>
          {roadMapCards.map((item, index) => (
            <RoadMapCard
              key={index}
              title={item.title}
              content={item.content}
              number={index + 1}
            />
          ))}
        </div>
      </section>
      <video className='RoadMap__video' autoPlay muted playsInline loop>
        <source src={smokeVideo} type='video/mp4'></source>
      </video>
    </div>
  );
}

export default RoadMap;
