import React from 'react';
import { motion } from 'framer-motion';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import { paragraphAnimation } from '../../utils/config';

import token from '../../assets/images/jngl-token.png'

import './GetInTouch.scss';
function GetInTouch() {
  return (
    <section className='GetInTouch'>
      {/* <div className='GetInTouch-title'>
        <AnimateTextInView className='Title' text='COMING SOON' type='h1' />
      </div> */}
      <div className='GetInTouch-content'>
        <a href="https://app.uniswap.org/#/swap?outputCurrency=0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155&chain=mainnet" target="_blank">
          <img src={token}>
          </img>
        </a>
      </div>
    </section>
  );
}

export default GetInTouch;
