import * as React from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Banner from '../../components/Banner/Banner';
import Header from '../../components/Header/Header';
import About from '../../components/About/About';
import FAQ from '../../components/FAQ/FAQ';
import Footer from '../../components/Footer/Footer';
import Artist from '../../components/Artist/Artist';
import RoadMap from '../../components/RoadMap/RoadMap';
import Features from '../../components/Features/Features';
import GetInTouch from '../../components/GetInTouch/GetInTouch';
import Preloader from '../../components/Preloader/Preloader';
import FeaturesSlider from '../../components/Features/FeaturesSlider/FeaturesSlider';


function Home() {

  const [loaded, setLoaded] = React.useState(false)

  const handleLoad = React.useMemo(() => {
    setLoaded(true)
  }, []);

  return (
    <>
      <Preloader handleLoad={handleLoad} />
      <Header siteTitle={`Title`} />
      <Banner/>
      <About />
      <Artist />
      <RoadMap />
      <FeaturesSlider />
      {/* <GetInTouch /> */}
      <Footer />
    </>
  );
}

export default Home;
