import * as React from "react"
import { BrowserRouter as Router, Route, Routes,useNavigate } from "react-router-dom";
import { useWeb3Context } from '../../context'
import FAQ from '../../components/FAQNFT/FAQNFT';
import Header from '../../components/HeaderWallet/HeaderWallet';
import Preloader from '../../components/Preloader/Preloader';



function Home() {

  let navigate = useNavigate()

  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  const [loaded, setLoaded] = React.useState(false)

  const [count, setCount] = React.useState(0)

  const handleLoad = React.useMemo(() => {
    setLoaded(true)
  }, []);


  return (
    <>
      <Preloader handleLoad={handleLoad} />
      <Header siteTitle={`Title`} />
      <FAQ />
    </>
  );
}

export default Home;
