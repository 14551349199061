import { motion } from 'framer-motion';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';

import './FAQNFT.scss';
const questions = [
  {
    heading: 'How do I stake my assets? What can I stake?',
    content: `You can stake Supreme Kong, Supreme Kong Gen 2, Supreme Bananas, Supreme Mutant Bananas.`
  },
  {
    heading: 'Which tokens earn you the most?',
    content: `Kongs earn the most, followed by Mutant then Supreme Bananas.`
  },
  {
    heading: 'Where does the NFT go when I stake?',
    content: `Your NFT is sent to the staking contract during your staked period.`
  },
  {
    heading: 'How do I unstake my NFT?',
    content: `After the end of your stake period, click Unstake. You lose all tiered bonuses.`
  },
  {
    heading: 'What do my tokens earn?',
    content: `You will earn $KONG token from staking your assets.`
  },
  {
    heading: 'What can I get with my $KONG token?',
    content: `The Kong marketplace has whitelist scrolls, leveling potions, and multiple ETH NFTs and SOL NFTs available for purchase in our marketplace. We will be continuously adding new NFTs and features to our staking, optimizing your experience and claimables.`
  },
  {
    heading: 'What does the Supreme Mutant Banana give me?',
    content: `Provides you a higher yield than all bananas combined and is a guaranteed mint pass in order to be able to mint Gen 2 for non-genesis Kong holders.`,
  }, 
  {
    heading: 'How do I redeem my NFTs purchased with $KONG?',
    content: `This process will become automated. For now, it is manual. Open a ticket in the Supreme Kong discord and provide your staking ETH address or your SOL wallet for a SOL redeemable.`,
  }, 
  {
    heading: 'What NFTs are available in the marketplace?',
    content: `Kong marketplace is the first multi-chain marketplace. You can purchase ETH and SOL NFTs using $KONG.`,
  }, 
];

const staking = [
  {
    heading: 'What do I need to get a Mutant Supreme Banana?',
    content: `You need 5 Supreme Bananas. Go to our website. Click Mutate.`,
  },
  {
    heading: 'How many can I mint at once?',
    content: `You can mint as many as you want as long as supply is available.`,
  },
  {
    heading: 'What happens to my 5 Supreme Bananas?',
    content: `They get “burnt” and replaced with 1 Mutant Banana.`,
  },
  {
    heading: 'What does the Supreme Mutant Banana give me?',
    content: `Provides you a higher yield than all bananas combined and is a guaranteed mint pass in order to be able to mint Gen 2 for non-genesis Kong holders.`,
  },
  {
    heading: 'How do I stake my assets? What can I stake?',
    content: `You can stake Supreme Kong, Supreme Kong Gen 2, Supreme Bananas, Supreme Mutant Bananas.`
  },
  {
    heading: 'Which tokens earn you the most?',
    content: `Kongs earn the most, followed by Mutant then Supreme Bananas.`
  },
  {
    heading: 'Where does the NFT go when I stake?',
    content: `Your NFT is sent to the staking contract during your staked period.`
  },
  {
    heading: 'How do I unstake my NFT?',
    content: `After the end of your stake period, click Unstake.`
  },
  {
    heading: 'What do my tokens earn?',
    content: `You will earn $KONG token from staking your assets.`
  },
  {
    heading: 'What can I get with my $KONG token?',
    content: `The Kong marketplace has whitelist scrolls, leveling potions, and multiple ETH NFTs available for purchase in our marketplace.`
  },
];


const reward = [
  {
    heading: 'Tier Bonus',
    content: `The longer you stake, the higher tier bonus you unlock yielding you more:`,
    ul:["7d +3%","14d +5%","30d +7%","3 mo +10%","1 yr +15%"]
  },
  {
    heading: 'Rewards Schedule',
    content: `Rewards schedule for your Supreme Kong, Supreme Kong Gen 2, Supreme Bananas, and Supreme Mutant Bananas NFTs:`,
    ul:["1 Supreme Kong staked will earn 0.015 $KONG per second","1 Supreme Kong Gen 2 staked will earn 0.003 $KONG per second","1 Supreme Bananas staked will earn 0.003234375 $KONG per second","1 Supreme Mutant Bananas staked will earn 0.0163875 $KONG per second"]
  },
  {
    heading: 'Bonus Rewards Schedule',
    content: `You can earn bonus $KONG when you stake these pairs below. The bonus $KONG will be displayed right above your claimable token per asset:`,
    ul:["1 Supreme Kong + 1 Supreme Bananas staked will earn 0.0008984375 $KONG for Supreme Kong and 0.0001796875 for Supreme Bananas per second","1 Supreme Kong + 1 Supreme Mutant Bananas staked will earn 0.00129375 $KONG for Supreme Kong and 0.00129375 $KONG for Supreme Mutant Bananas per second","1 Supreme Kong Gen 2 + 1 Supreme Bananas staked will earn 0.0001796875 $KONG for Supreme Kong Gen 2 and 0.0000359375 for Supreme Bananas per second","1 Supreme Kong Gen 2 + 1 Supreme Mutant Bananas staked will earn 0.00025875 $KONG for Supreme Kong Gen 2 and 0.00025875 $KONG for Supreme Mutant Bananas per second"]
  },
  {
    heading: 'Locking Period',
    content: `For every NFTs staked (Supreme Kong, Supreme Kong Gen 2, Supreme Bananas, and Supreme Mutant Bananas), there will be a 7 days locking period. During the 7 days, you will be unable to unstake your NFTs. You will still earn $KONG rewards during the locking period. After 7 days you'll be able to unstake any of your assets. During locking period you can claim at any time. Once you unstake you lose all tiered bonuses.`,
    ul:[]
  },
  {
    heading: 'Marketplace',
    content: `You'll be able to spend your $KONG in the marketplace to claim rewards. Some rewards will have a maximum per wallet that you can claim. It will be noted on the items that have a limit.`,
    ul:[]
  },
  
];


const leveling = [
  {
    heading: 'What assets can I level up?',
    content: `Currently, you can level up your Supreme Mutant Bananas. Leveling will become available for Gen 2 Kongs once we have our next mint later this year during Q4.`,
    ul:[]
  },
  {
    heading: 'Why should I level up my NFTs?',
    content: `When you level up your NFT, your yield rate of $KONG increases and new metadata is assigned to your NFT. All exchanges will show your NFTs level as a searchable trait, providing your NFT more value. To obtain faster yields and skip the leveling process, you can head to Open Sea and purchase any level asset you would like.`,
    ul:[]
  },
  {
    heading: 'What do I need to level up my NFTs?',
    content: `You will need leveling potions from our marketplace. As your NFTs level up, they will require more potions at each level.`,
    ul:[]
  },
  {
    heading: 'How many levels are there? How does it work?',
    content: `There are 10 levels currently obtainable for your NFTs. Level 1-5 is 100% guaranteed chance of level up. From level 6-10 it is not 100% guaranteed and actually is increasingly harder to obtain each level after Level 5. If you fail a level up attempt, after Level 6, your NFT will downgrade 1 level. Your NFTs once past level 5 cannot downgrade lower than level 5.`,
    ul:[]
  },
  {
    heading: 'How do I level up my NFTs?',
    content: `You go to staking page, and click on level up below your assets which have leveling available to them.`,
    ul:[]
  },
];


function FAQ() {
  return ( <>
    <section id='faq' className='FAQ container' style={{color:'#F2B90C !important' }}>
      <h1 className='Title' style={{color:"#F2B90C"}}>Staking Mechanics</h1>
      <div className='FAQ__Accordion'>
        <Accordion allowZeroExpanded color='#F2B90C'>
          {reward.map((item, index) => (
            <AccordionItem key={index} color='#F2B90C'>
              <AccordionItemHeading color='#F2B90C'>
                <AccordionItemButton color='#F2B90C'>
                  <motion.h3 {...paragraphAnimation} style={{color:"#F2B90C"}}>{item.heading}</motion.h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {item.content}
                <div style={{marginTop:"10px"}}></div>
                {item.ul.map((item,index)=> (
                  <li key={index} style={{marginLeft:"10px"}}>{item}</li>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
    <section id='faq' className='FAQ container' style={{color:'#F2B90C !important' }}>
      <h1 className='Title' style={{color:"#F2B90C"}}>Leveling Mechanics</h1>
      <div className='FAQ__Accordion'>
        <Accordion allowZeroExpanded color='#F2B90C'>
          {leveling.map((item, index) => (
            <AccordionItem key={index} color='#F2B90C'>
              <AccordionItemHeading color='#F2B90C'>
                <AccordionItemButton color='#F2B90C'>
                  <motion.h3 {...paragraphAnimation} style={{color:"#F2B90C"}}>{item.heading}</motion.h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {item.content}
                <div style={{marginTop:"10px"}}></div>
                {item.ul.map((item,index)=> (
                  <li key={index} style={{marginLeft:"10px"}}>{item}</li>
                ))}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
    <section id='faq' className='FAQ container' style={{color:'#F2B90C !important' }}>
    <h1 className='Title' style={{color:"#F2B90C"}}>FAQ</h1>
    <div className='FAQ__Accordion'>
      <Accordion allowZeroExpanded color='#F2B90C'>
        {questions.map((item, index) => (
          <AccordionItem key={index} color='#F2B90C'>
            <AccordionItemHeading color='#F2B90C'>
              <AccordionItemButton color='#F2B90C'>
                <motion.h3 {...paragraphAnimation} style={{color:"#F2B90C"}}>{item.heading}</motion.h3>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>{item.content}</AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
    </section>
  </>
  );
}
export default FAQ;
