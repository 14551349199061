import { motion } from 'framer-motion';
import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';

import './FAQ.scss';
const questions = [
  {
    heading: 'WHAT IS AN NFT?',
    content: `NFT stands for 'non-fungible token. An NFT is basically data that is accounted for in a digital ledger, and that data represents something specific and unique. An NFT can, for example, represent a unique piece of art or a game token.`,
  },
  {
    heading: 'How Can I Buy a Supreme Kong NFT?',
    content: `You will be able to buy on our website when we launch. Join our discord to have more information about our launch date.`,
  },
  {
    heading: 'WHAT DOES “MINT” MEAN?',
    content: `NFT Minting is the process by which your digital art or digital content becomes a part of the Ethereum blockchain.`,
  },
  {
    heading: 'HOW MUCH DOES IT COST BUY ONE OF THE Supreme Kong?',
    content: `The Price of Minting and Sale will be announced soon.`,
  },
  {
    heading: 'WHY DID YOU CHOOSE Supreme Kongs?',
    content: `Renowned artist Hossein Diba has after weeks of work decided to use their knowledge and experience with major studios to bring a project that has never be seen before to the NFT space.` },
];

function FAQ() {
  return (
    <section id='faq' className='FAQ container'>
      <AnimateTextInView className='Title' text={'FAQ'} type='h1' />
      <div className='FAQ__Accordion'>
        <Accordion allowZeroExpanded>
          {questions.map((item, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <motion.h3 {...paragraphAnimation}>{item.heading}</motion.h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>{item.content}</AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
}
export default FAQ;
