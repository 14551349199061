import { motion } from 'framer-motion';
import React from 'react';
import { paragraphAnimation } from '../../utils/config';
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import Kong from '../../assets/images/kong-img.png'


import './Banner.scss';

export default function Banner() {
  return (
    <motion.section 
    {...paragraphAnimation}
    id='banner' className='Banner-new'>
      <div className='Banner-new__content'>
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Banner__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
        <div className='flex flex-section'>
          <div className='DiscordButton-new' style={{marginLeft:'30px'}}>
            <a href='https://opensea.io/collection/supremekong' target='_blank'>
              Buy on OpenSea
            </a>
          </div>
        </div>
      </div>
    </motion.section>
  );
}
