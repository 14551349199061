import { motion } from 'framer-motion';
import React , {useEffect, useState} from 'react';
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import Header from '../../components/HeaderJngl/Header';
import EntryList from './EntryList'; 

import { useParams } from 'react-router-dom';

import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';

import banner from '../../assets/images/staking-banner.png'
import logo from '../../assets/images/staking-logo.png'

import token1 from '../../assets/images/token-kong.png'
import token2 from '../../assets/images/token-db.png'

import liveWin from '../../assets/images/live_win.svg'
import winnerCard from '../../assets/images/winner-card.png'

import sandIcon from '../../assets/images/sand-icon.png'

import './MP.scss';
import { useWeb3Context } from '../../context'

import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'

const marketplaceAbi = require('../../helper/marketplace.json')
const raffleAbi = require('../../helper/raffle.json')
const stakingAbi = require('../../helper/stakingOffchain.json')
const alphaAbi = require('../../helper/alpha.json')

let web3Modal;

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function Home() {
  const tokens = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokens}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  const { id } = useParams();

  const [kongBalance, setKongBalance] = React.useState(0)
  const [kongBalanceRaw, setKongBalanceRaw] = React.useState(0)

  const [showModal, setShowModal] = React.useState(false)
  const [showUnstakeModal, setShowUnstakeModal] = React.useState(false)

  const stakingAddress = "0xdf88AEa19eB0bdc40135e6C15bc9AAD9b06Ed40F"

  const [entries, setEntries] = useState([]);
  const [page, setPage] = useState(1); // Track the current page of entries

  const [buyAmount, setBuyAmount] = React.useState(1)
  const [prices, setPrices] = React.useState(0)
  const [updatedPriceDisplay, setUpdatedPriceDisplay] = useState('');

  const [stakingBalance, setStakingBalance] = React.useState(0)
  const [earnedBalance, setEarnedBalance] = React.useState(0)
  const [stakingBalanceRaw, setStakingBalanceRaw] = React.useState(0)

  const [stakeMax, setStakeMax] = React.useState(0)

  const [entryAmount, setEntryAmount] = React.useState(0)



  const marketplaceAddress = "0x1f2eF28F3e36838fea994e1Be564be9E5993E0e2"

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  const [Listing, setListing] = React.useState();
  const base_uri = "https://api.supremekong.com";

  useEffect(async() => {
    //Runs only on the first render
    if(web3Provider){
      console.log('web3Provider')
      // loadToken()
      loadListing()
      loadEntries()
    }else{
    }
  }, [web3Provider]);

  const loadEntries = async () => {
    try {
      const query = `
        {
          participantEntereds(first: 100) {
            participant
          }
        }
        `;
        const listing = await axios.post('https://api.studio.thegraph.com/query/46966/kong-raffle-goerli/version/latest', {query: query})

        let listingData = listing.data.data.participantEntereds;

        console.log(listingData)

        setEntryAmount(listingData.length)

        const entryData = listingData;

      // Update the state with the loaded entries
      setEntries(entryData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLoadMore = () => {
    // Increment the page and load more entries
    loadEntries();
  };

  const [selectedImage, setSelectedImage] = React.useState();

  const handleItemClick = (item) => {
    setSelectedImage(item.image); // You may need to adjust this line depending on your data structure
  };

  const loadListing = async() => {
    try{
      setListing()

      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, raffleAbi, signer);
      const listing = {}
      const listingInfo = await contract.getPool(id);

      const poolInfo = await contract.pools(id);

      const priceInEther = ethers.utils.formatEther(listingInfo[5]);
      listing.name = listingInfo[0]
      listing.price = listingInfo[5]
      setPrices(parseFloat(priceInEther))
      listing.priceDisplay = parseFloat(priceInEther).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
      setUpdatedPriceDisplay(listing.priceDisplay)
      listing.end = listingInfo[9];
      listing.image = "https://marketplace.alphashards.com/images/"+listing.collectionId+".avif"

      const timestamp = poolInfo[10].toNumber()
      const date = new Date(timestamp * 1000);
      const formattedDate = date.toLocaleString('en-US', {day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric'});
      
      listing.date = formattedDate;
      if(listingInfo[9] == true){
        const winner = await contract.getWinnerAddress(id);
        listing.winner = winner;
      }

      // Wait for all metadata requests to complete and filter out any null results
    
      setListing(listing)
    } catch(err) {
      console.log(err)
    }
  }

  useEffect(() => {
    let updatedPrice = prices * buyAmount;
    updatedPrice = updatedPrice.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2});
    setUpdatedPriceDisplay(updatedPrice); // Adjust the formatting as needed

  }, [buyAmount]);


  const claim = async () => {

    try{
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, raffleAbi, signer);

      const tokenContract = new ethers.Contract("0x623dc0715f19571a55Cbad79ef52042007E80c92", alphaAbi, signer);

      // Check if approval is necessary
      const allowance = await tokenContract.allowance(address, marketplaceAddress);
      if (allowance.lt(Listing.price.mul(buyAmount))) {
        try {
          // Notify user about the approval request
          await toast.info('Requesting Approval');
          
          // Approve the Staking Contract to spend the tokens
          const approveTx = await tokenContract.approve(marketplaceAddress, Listing.price.mul(buyAmount));
          await approveTx.wait();
      
          // Notify user about the successful approval
          await toast.success('Approval Successful');
        } catch (error) {
          // Handle any errors that occur during the approval process
          console.error('Approval Error:', error);
          // You might want to notify the user about the error here
        }
      }

      const tx = await contract.joinPool(id,buyAmount)
      await tx.wait()
      setBuyAmount(1)
      toast.success('Transaction Success')
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }

  const increment = () => {
    setBuyAmount(buyAmount+1)
  }

  const decrement = () => {
    if(buyAmount > 1){
      setBuyAmount(buyAmount-1)
    }
  }

  const claimWin = async () => {

    try{
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(marketplaceAddress, raffleAbi, signer);

      const tx = await contract.claimPrize(id)
      await tx.wait()
      toast.success('Transaction Success')
    }catch(err){
      console.log(err)
      toast.error('Transaction Failed')
    }
  }


  return (
    <>
    <Header siteTitle={`Title`}/>
    <div
    id='Home' className='RaffleDetail'>
      <div className='RaffleDetail__content'>          
        <div className='raffledetail-flex-container'>
          
          <div className='raffledetail-flex-left'>
            <img src="https://i.seadn.io/gcs/files/0d19fe1cdad7e92d36484c081d8c329a.png?auto=format&dpr=1&w=3840"></img>
            <div className="raffledetail-flex-desc-title">
              DESCRIPTION
            </div>
            <div style={{borderTop: '1px solid #ccc', margin: '10px 0'}}></div>

            <div style={{color:'rgba(109, 115, 133, 1)',fontSize:'12px',fontFamily:'Lato'}}>Ancient shards are the genesis token within ALPHA ecosystem
                consisting of 8 tiers of shards that are needed.
            </div>
          </div>

          <div className='raffledetail-flex-right'>
            <div className="raffledetail-flex-desc-title">
              {Listing?.name ?? ''}
            </div>
            <div style={{borderTop: '1px solid #ccc', margin: '10px 0'}}></div>
            <div className='raffledetail-flex-desc-timer'>
              <img src={sandIcon}></img>
              { Listing?.end != true ?
                <p style={{marginLeft:'10px',fontSize:'18px'}}> CLOSES: {Listing?.date ?? ''} </p>
              :
                <p style={{marginLeft:'10px',fontSize:'18px'}}> CLOSED: </p>
              }
            </div>
            <div className='raffledetail-flex-card'>
              <div className='raffledetail-flex-card-title'>
                ENTER COMPETITION
              </div>
              <div className='raffledetail-flex-card-detail'>
                  <div className='raffledetail-flex-card-detail-price'>
                    <p> {updatedPriceDisplay ?? ''} $JNGL</p>
                  </div>

                  <div className='raffledetail-flex-card-detail-price'>
                    <p > Entries : {entryAmount} </p>
                  </div>
                { Listing?.end != true &&
                  <div className='raffle-item-card-info-button'>
                      <div className='raffle-item-card-info-button-buy' onClick={claim}>
                        ENTER
                      </div>
                  </div>
                }

                  <div className='marketplace-item-card-info-button'>
                    <div className='marketplace-item-card-info-button-amount' >
                      {buyAmount}
                    </div>
                    <div className='marketplace-item-card-info-button-minus' onClick={decrement}>
                      -
                    </div>
                    <div className='marketplace-item-card-info-button-plus' onClick={increment}>
                      +
                    </div>

                  </div>

                { Listing?.winner == address &&
                    <div className='raffle-item-card-info-button'>
                        <div className='raffle-item-card-info-button-buy' onClick={claimWin}>
                          CLAIM
                        </div>
                    </div>
                  }
              </div>
            </div>
          </div>
        </div>
        <EntryList entries={entries} onLoadMore={handleLoadMore} />
      </div>
    </div>
    <footer className="footer">
        <div className="footer__section">
          <p className="footer__copyright">Copyright © 2023 $JNGL Token. All Rights Reserved.</p>
        </div>
        <div className="footer__section"> 
         <a href='https://etherscan.io/token/0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
          <a href='https://discord.com/invite/supremekong'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
          <a href='https://twitter.com/Supremekongnft'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
          <a href='https://opensea.io/collection/supremekong'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
          <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xcd02a882804f3eb0f2d80f94a04defcea038359f'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
          <a href='mailto:cyrus@supremekong.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
        </div>
    </footer>
    </>
  );
}
