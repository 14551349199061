import React from 'react';
import './Modal.scss';

function Modal({ isOpen, content, onClose, position }) {
  if (!isOpen) return null;

  return (
    <div className='Modal-overlay' onClick={onClose}>
      <div 
        className={`Modal-content ${position}`} 
        onClick={e => e.stopPropagation()}
      >
        <div className='Modal-Titleborder'>{content}</div>
        <div className='Modal-content-text'>
          
        <ul className="modal-list">
          {content == 'Phase 1' && 
            <>
              <li>Launch $JNGL</li>
              <li>Deflationary begins</li>
              <li>Reach 2000+ Holders</li>
              <li>Deploy SK1 Claim Pools</li> 
              <li>Deploy $JNGL microtransactions</li>
            </>
          }

          {content == 'Phase 2' && 
            <>
              <li>Reach 5000+ Holders</li>
              <li>Deploy Crafting System</li> 
              <li>Deploy $JNGL raffle dapp</li>
            </>
          }
        </ul>
          
        </div>
      </div>
    </div>
  );
}

export default Modal;
