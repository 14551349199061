import React from 'react';
import { motion } from 'framer-motion';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';

import Img1 from '../../assets/images/features/1.png';
import Img2 from '../../assets/images/features/2.png';

import './Features.scss';

function Features() {
  return (
    <section id='features' className='Features container'>
      <div className='Features__content'>
        <div className='Features__text'>
          <div className='fwrap flex'>
            <AnimateTextInView text='WILL' type='h2' />
            <AnimateTextInView text='YOU BE' type='h2' />
            <AnimateTextInView text='ACCEPTED AS' type='h2' />
            <AnimateTextInView text='THE STRONG' type='h2' />
            <AnimateTextInView text='AMONG' type='h2' />
            <AnimateTextInView text='THE STRONG?' type='h2' />
          </div>
          <motion.p className='section__p' {...paragraphAnimation}>
            The jungle breath with anticipation of Supreme Kong's arrival. But
            the jungle is only so big, and the Metaverse can only contain so
            much raw force.
          </motion.p>
          <motion.p className='section__p' {...paragraphAnimation}>
            The fate of the Metaverse will be decided on Mid February 2022.
          </motion.p>
          <motion.p className='section__p' {...paragraphAnimation}>
            If you care about your legacy in the Metaverse, you must take strong
            and immediate action to secure your own Supreme Kong — only 2000
            savage leaders may live on in power and pride.
          </motion.p>
          <button className='join_discord'>
            <a href='https://discord.com/invite/supremekong' target='_blank' rel="noreferrer">join our discord</a>
          </button>
        </div>
        <div className='flex Features__images'>
          <motion.div
            {...{
              initial: 'hidden',
              whileInView: 'visible',
              viewport: { once: true },
              transition: { duration: 1 },
              variants: {
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: 100 },
              },
            }}
          >
            <img src={Img1} alt='' />
          </motion.div>
          <motion.div
            {...{
              initial: 'hidden',
              whileInView: 'visible',
              viewport: { once: true },
              transition: { duration: 1, delay: 0.3 },
              variants: {
                visible: { opacity: 1, y: 100 },
                hidden: { opacity: 0, y: 200 },
              },
            }}
          >
            <img src={Img2} alt='' />
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default Features;
