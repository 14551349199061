import { motion } from 'framer-motion';
import React from 'react';
import { paragraphAnimation } from '../../utils/config';
import DiscordButton from '../lib/DiscordButton/DiscordButton';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';


import './Banner.scss';

export default function Banner() {
  return (
    <motion.section 
    {...paragraphAnimation}
    id='banner' className='Banner'>
      <div className='Banner__content'>
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Banner__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
        <div className='fjc flex fwrap'>
          <AnimateTextInView
            text='SUPREME'
            type='h1'
            className='Title'
          />
          <AnimateTextInView
            text='COLLECTION'
            type='h1'
            className='Title'
          />
          <AnimateTextInView
            text='OF'
            type='h1'
            className='Title'
          />
          <AnimateTextInView
            text='JUNGLE LABZ'
            type='h1'
            className='Title'
          />
        </div>
        <DiscordButton text='Buy on OpenSea' />
      </div>
    </motion.section>
  );
}
