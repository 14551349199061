import { motion } from 'framer-motion';
import React from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import Tokenomic from '../../assets/images/tokenomic.png'
import './About.scss';
import AboutSlider from './AboutSlider';

function About() {
  return (
    <div id='about' className='About-jngl'>
      <div className='container'>
        {/* <motion.h1 {...paragraphAnimation} className='About__title Title'>
          UNQUESTIONED DOMINANCE FINALLY UNLEASHED
        </motion.h1> */}
        <div className='flex fwrap fjc '>
          <div className='junglenomic-title'>
            Junglenomics
          </div>

          <div className='junglenomics-table'>
            <h1>10,000,000</h1>
            <p>Total Supply</p>
          </div>

          <div className='junglenomics-table-bottom'>
            <div className='jungleinsnomic-tabletop'>
              <div className='jungleins-tabletop-left'>
                <div className='junglenomic-contents'>
                  <div className='junglenomic-contents-left'>Presale</div>
                  <div className='junglenomic-contents-middle'>3,300,000</div>
                  <div className='junglenomic-contents-right'>33%</div>
                </div>
                <div className='junglenomic-contents'>
                  <div className='junglenomic-contents-left'>CEX/DEX</div>
                  <div className='junglenomic-contents-middle'>1,000,000</div>
                  <div className='junglenomic-contents-right'>10%</div>
                </div>
                <div className='junglenomic-contents'>
                  <div className='junglenomic-contents-left'>JL Treasury</div>
                  <div className='junglenomic-contents-middle'>700,000</div>
                  <div className='junglenomic-contents-right'>7%</div>
                </div>
                <div className='junglenomic-contents'>
                  <div className='junglenomic-contents-left'>Rewards</div>
                  <div className='junglenomic-contents-middle'>2,000,000</div>
                  <div className='junglenomic-contents-right'>20%</div>
                </div>
                <div className='junglenomic-contents'>
                  <div className='junglenomic-contents-left'>Gen 1 Pool</div>
                  <div className='junglenomic-contents-middle'>2,000,000</div>
                  <div className='junglenomic-contents-right'>20%</div>
                </div>
                <div className='junglenomic-contents'>
                  <div className='junglenomic-contents-left'>Launch LP</div>
                  <div className='junglenomic-contents-middle'>1,000,000</div>
                  <div className='junglenomic-contents-right'>10%</div>
                </div>
              </div>
              <div className='jungleins-tabletop-left'>
                <div className='junglenomics-table-middle'>
                  <div style={{display:'flex',textAlign:'left'}}>
                    <div style={{marginRight:'auto'}}>
                      <div style={{color:"green"}}>ETH</div>
                      <div>Collected</div>
                      <div>Launch LP</div>
                      <div>Launch Price</div>
                      <div>Initial Mcap</div>
                      <div style={{color:"green"}}>&nbsp;&nbsp;</div>
                      <div style={{color:"green"}}>&nbsp;&nbsp;</div>
                      <div style={{color:"green"}}>USD</div>
                      <div>Launch LP</div>
                      <div>Launch Price</div>
                      <div>Initial Mcap</div>
                    </div>
                    <div style={{marginLeft:'auto',textAlign:"right"}}>
                     <div style={{color:"green"}}>&nbsp;&nbsp;</div>
                     <div>200</div>
                     <div>60</div>
                     <div>0.00006</div>
                     <div>600</div>
                     <div style={{color:"green"}}>&nbsp;&nbsp;</div>
                     <div style={{color:"green"}}>&nbsp;&nbsp;</div>
                     <div style={{color:"green"}}>&nbsp;&nbsp;</div>
                     <div>$97,260</div>
                     <div>$0.09726</div>
                     <div>$972,600</div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className='jungleins-tabletop-right' style={{marginTop:'10px'}}>
              <div className='junglenomic-contents-2'>
                  <div className='junglenomic-contents-left'>ETH Raised</div>
                  <div className='junglenomic-contents-rights'>200 ETH</div>
                </div>
                <div className='junglenomic-contents-2'>
                  <div className='junglenomic-contents-left'>Tier 1 worth</div>
                  <div className='junglenomic-contents-rights'>0.0891</div>
                </div>
                <div className='junglenomic-contents-2'>
                  <div className='junglenomic-contents-left'>Tier 1 Token</div>
                  <div className='junglenomic-contents-rights'>1,485</div>
                </div>
                <div className='junglenomic-contents-2'>
                  <div className='junglenomic-contents-left'>Tier 1 Buy In</div>
                  <div className='junglenomic-contents-rights'>0.09</div>
                </div>
                <div className='junglenomic-contents-2'>
                  <div className='junglenomic-contents-left'>Tier 1 Paid</div>
                  <div className='junglenomic-contents-rights'>99%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AboutSlider /> */}
    </div>
  );
}

export default About;
