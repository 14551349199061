import React, { useState, useRef } from 'react';
import Modal from '../Modal/Modal';
import './About.scss';
import Tokenomic from '../../assets/images/jngl_map.png'

// your other imports...

function About() {
  const [modalContent, setModalContent] = useState(null);
  const [modalPosition, setModalPosition] = useState(null);
  const timeoutRef = useRef(null);  // to store the timeout ID

  function handleMouseOver(side) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if(side == "left"){
      setModalContent("Phase 1");
    }else{
      setModalContent("Phase 2");
    }
    setModalPosition(side);
  }

  function handleMouseOut() {
  }

  return (
    <div id='about' className='About-jngl'>
      {/* your other components... */}

      <div className='flex fwrap fjc ' style={{flexDirection:'column'}}>
          <div className='junglenomic-title'>
            Junglemap
          </div>

          <div className='junglenomic-table'>
            <div 
              className='hover-area left' 
              onClick={() => handleMouseOver('left')} 
              onMouseLeave={handleMouseOut}
            />
            <img src={Tokenomic}></img>
            <div 
              className='hover-area right' 
              onClick={() => handleMouseOver('right')} 
              onMouseLeave={handleMouseOut}
            />
          </div>
        </div>
      
      <Modal 
        isOpen={!!modalContent} 
        content={modalContent} 
        position={modalPosition} 
        onClose={() => setModalContent(null)} 
      />

      {/* your other components... */}
    </div>
  );
}

export default About;