import React from 'react';
import Slider from 'react-slick';

import Image1 from '../../../assets/images/features/slider/1.jpg';
import Image2 from '../../../assets/images/features/slider/2.jpg';
import Image3 from '../../../assets/images/features/slider/3.jpg';
import Image4 from '../../../assets/images/features/slider/4.jpg';
import Image5 from '../../../assets/images/features/slider/5.jpg';
import Image6 from '../../../assets/images/features/slider/6.jpg';
import Image7 from '../../../assets/images/features/slider/7.jpg';
import Image8 from '../../../assets/images/features/slider/8.jpg';
import Image9 from '../../../assets/images/features/slider/9.jpg';
import Image10 from '../../../assets/images/features/slider/10.jpg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './FeaturesSlider.scss';

let imgArr = [
  Image1,
  Image2,
  Image3,
  Image4,
  Image5,
  Image6,
  Image7,
  Image8,
  Image9,
  Image10
];

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 6,
  slidesToScroll: 1,
  autoplay: true,
  pauseOnHover: false,
  speed: 4000,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 360,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};
function FeaturesSlider() {
  return (
    <div className='FeaturesSlider'>
      <Slider {...settings}>
        {imgArr.map((img, index) => {
          return (
            <div key={index}>
              <img src={img} alt='' />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default FeaturesSlider;
