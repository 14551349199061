import * as React from "react";
import DiscordButton from "../../components/lib/DiscordButton/DiscordButton";
import Preloader from "../../components/Preloader/Preloader";
import Header from "../../components/HeaderWallet/HeaderWallet";
import { useWeb3Context } from "../../context";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import mutantbanana from "../../assets/images/supreme-bananas.jpeg";
import { useNavigate } from "react-router-dom";
import { HmacSHA256, enc } from "crypto-js";

import potion from "../../assets/images/level_potion.png";

import "./Marketplace.scss";
import axios from "axios";

import ethLogo from "../../assets/images/eth_logo_black.png";
import solLogo from "../../assets/images/solana_logo.png";

import token2 from "../../assets/images/token-kong.png";

const mutantbananaAbi = require("../../helper/mutantBanana.json");
const bananaAbi = require("../../helper/bananaAbi.json");

function Marketplace() {
  const tokens = localStorage.getItem("token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${tokens}`;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  let navigate = useNavigate();

  const burnCost = 5;

  const base_uri = "https://api.supremekong.com";
  //const base_uri = "http://localhost:8001";
  const { web3Provider, connect, disconnect, address } = useWeb3Context();

  const [loaded, setLoaded] = React.useState(false);

  const [count, setCount] = React.useState(0);

  const [bananaOwned, setBananaOwned] = React.useState(0);

  const [maxAmount, setMaxAmount] = React.useState(0);

  const [amount, setAmount] = React.useState(0);

  const [balance, setBalance] = React.useState(0);

  const [claimedBanana, setClaimedBanana] = React.useState(0);

  const [claimedPotion, setClaimedPotion] = React.useState(0);

  const [claimedWhitelist, setClaimedWhitelist] = React.useState(0);

  const [claimedReunion, setClaimedReunion] = React.useState(0);

  const [claimedMonkai, setClaimedMonkai] = React.useState(0);

  const [claimedPayc, setClaimedPayc] = React.useState(0);

  const [claimedYoots, setClaimedYoots] = React.useState(0);

  const [approvedBanana, setApprovedBanana] = React.useState(false);

  const [discount, setDiscount] = React.useState(0);

  const [marketplace, setMarketplace] = React.useState([]);

  const handleLoad = React.useMemo(() => {
    setLoaded(true);
  }, []);

  React.useEffect(async () => {
    //Runs only on the first render
    if (web3Provider) {
      loadMarketplace();
      getBalance();
    }

    if (web3Provider == null) {
      setTimeout(() => {
        setCount(count + 1);
      }, 200);
    }

    if (count >= 14) {
      navigate("/connect");
    }
  }, [web3Provider, count]);


  const loadMarketplace = async () => {
    const res = await axios.get(base_uri + "/getMarketplace", {
      headers: {
        api_key:
          "R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m", //the token is a variable which holds the token
      },
    });
    let marketplaceData = res.data.result;
    await Promise.all(
      marketplaceData.map(async (market) => {
        const resp = await axios.get(
          base_uri + "/getClaimedPrize?address=" + address + "&id=" + market.id,
          {
            headers: {
              api_key:
                "R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m", //the token is a variable which holds the token
            },
          }
        );
        market.claimed = resp.data.claimed;

        return market;
      })
    );

    setMarketplace(marketplaceData);
  };

  const getBalance = async () => {
    const res = await axios.get(base_uri + "/getBalance?address=" + address, {
      headers: {
        api_key:
          "R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m", //the token is a variable which holds the token
      },
    });
    setBalance(res.data.result[0].balance);
  };

  const buy = async (id) => {
    const _id = toast.loading("Claiming in progress");
    let now = Math.floor(Date.now() / 1000);
    const body = {
      address: address,
      id: id,
      amount: 1,
      timestamp: now,
    };
    const hashkey = enc.Hex.stringify(
      HmacSHA256(JSON.stringify(body), process.env.REACT_APP_SECRET_KEY)
    );

    let res

      res = await axios.post(base_uri + "/kongOffChain", body, {
        headers: {
          api_key:
          "R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m", //the token is a variable which holds the token
        hash_key: hashkey,
        }
      })
    toast.update(_id, {
      render: res.data.msg,
      type: "success",
      autoClose: 1000,
      isLoading: false,
    });
    getBalance();
    loadMarketplace();
  };

  const increment = () => {
    if (amount < maxAmount) {
      setAmount(amount + 1);
    }
  };

  const decrement = () => {
    if (amount > 0) {
      setAmount(amount - 1);
    }
  };

  return (
    <>
      <Preloader handleLoad={handleLoad} />
      <Header siteTitle={`Title`} balances={balance} />
      {/* <motion.h1 
            {...paragraphAnimation}
            className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
      <div className="marketplace-container">
        <div className="marketplace-title"></div>

        <div className="marketplace-title">Available Rewards</div>
        {marketplace.map((object, i) => (
          <div className="marketplace-card" key={i}>
            <div className="marketplace-card-flex">
              {object.id == 4 ? (
                <div className="marketplace-card-flex-left">
                  <video
                    autoPlay
                    loop
                    playsInline
                    muted
                    src="https://assets.supremekong.com/bananas/banana.mp4"
                  ></video>
                </div>
              ) : (
                <div className="marketplace-card-flex-left">
                  <img src={object.image_url}></img>
                </div>
              )}

              <div className="marketplace-card-flex-right">
                <div className="title">
                  <p>{object.title} </p>
                  {object.ticker != "-" && object.ticker == "eth" && (
                    <>
                      <img className="img-eth" src={ethLogo}></img>{" "}
                      <p>{object.fp.toFixed(3)} </p>
                    </>
                  )}
                  {object.ticker != "-" && object.ticker == "sol" && (
                    <>
                      <img className="img-sol" src={solLogo}></img>{" "}
                      <p>{object.fp.toFixed(3)} </p>
                    </>
                  )}
                </div>
                <div className="desc"> {object.description} </div>
                <div className="desc">
                  {" "}
                  <b style={{ marginTop: "2px", fontSize: "12px" }}>
                    Price
                  </b>{" "}
                </div>
                <div className="priceCard">
                  <div className="priceTxt">
                    {object.price > 0 && (
                      <>
                        <img
                          src={token2}
                          style={{ width: "30px", height: "30px" }}
                        ></img>
                        <p
                          style={{
                            height: "fit-content",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          {object.price.toLocaleString()}
                        </p>
                      </>
                    )}
                  </div>
                </div>

                <div className="balance">
                  <p
                    style={{
                      height: "fit-content",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    {" "}
                    Your balance:{" "}
                  </p>
                  <img
                    src={token2}
                    style={{ width: "30px", height: "30px" }}
                  ></img>
                  <p
                    style={{
                      height: "fit-content",
                      marginTop: "auto",
                      marginBottom: "auto",
                    }}
                  >
                    {" "}
                    {balance.toLocaleString()}{" "}
                  </p>
                </div>
                {object.unlimited == 0 && (
                  <div className="supply"> {object.supply} Available </div>
                )}

                <div className="supply">
                  {" "}
                  You have Claimed {object.claimed}{" "}
                </div>

                {object.supply > 0 && (
                  <button
                    className="DiscordButton"
                    style={{
                      paddingLeft: "80px",
                      paddingRight: "80px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                    onClick={() => buy(object.id)}
                  >
                    Claim
                  </button>
                )}

                {object.supply <= 0 && (
                  <button
                    disabled
                    className="UnstakeButton"
                    style={{
                      paddingLeft: "80px",
                      paddingRight: "80px",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                  >
                    Sold Out
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Marketplace;
