import { motion } from 'framer-motion';
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import './RoadMapCard.scss';

const options = {
  rootMargin: '0px',
  threshold: [0, 0.25, 0.5, 0.75, 1],
};

function RoadMapCard({ title, content, number }) {
  const [value, setValue] = React.useState(0);
  let ref = React.useRef({});

  React.useEffect(() => {
    let observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        intersectingHandler(entry.isIntersecting, entry);
      });
    }, options);
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, []);

  function intersectingHandler(intersecting, entry) {
    if (intersecting && +value !== 100) {
      setValue(entry.intersectionRatio * 100);
    }
  }

  return (
    <div className='RoadMapCard'>
      <div  className='RoadMapCard__CircularProgressbar'>
        <div ref={ref}>
          <CircularProgressbar
            styles={buildStyles({
              strokeLinecap: 'round',
              pathTransitionDuration: 0.5,
              textColor: '#fff',
              trailColor: '#fff',
              backgroundColor: '#29FFED',
            })}
            // strokeWidth={6}
            text={`0${number}`}
            value={value}
          />
        </div>
      </div>
      <div className='RoadMapCard__content'>
        <motion.h3
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          variants={{
            visible: { opacity: 1, x: 0 },
            hidden: { opacity: 0, x: 5 },
          }}
        >
          {title}
        </motion.h3>
        <motion.p
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          transition={{ duration: 1 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        >
          {content}
        </motion.p>
      </div>
    </div>
  );
}

export default RoadMapCard;
