import * as React from "react"

import './Mutate.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate, useParams } from "react-router-dom";
import { ethers } from 'ethers'
import { toast } from 'react-toastify';
import axios from "axios";
import mutantbanana from '../../assets/images/supreme-bananas.jpeg'
import arrow from '../../assets/images/arrow.png'
import ProgressBar from "../../components/Progressbar/Progressbars";
import { fail } from "assert";
import { HmacSHA256, enc } from 'crypto-js';

const mutantbananaAbi = require('../../helper/bananaAbi.json')
const stakingAbi = require('../../helper/stakeAbi.json')
const bananaAbi = require('../../helper/bananaAbi.json')

let interval;

function Mint() {
    const tokens = localStorage.getItem('token');
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokens}`;
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    let navigate = useNavigate()

    let { id } = useParams();

    //const base_uri = "http://localhost:8001";
    const base_uri = "https://api.supremekong.com";


    const bananaContract = process.env.REACT_APP_BANANA_CONTRACT;
    const mutantBananaContract = process.env.REACT_APP_MUTANT_BANANA_CONTRACT;
    const stakingContract = process.env.REACT_APP_STAKING_CONTRACT;

    const burnCost = 5;

    const arrayCosts = [1,2,3,5,6,8,10,12,14,18]

    const arrayBonus = ["0%","2%","4%","6%","8%","10%","15%","25%","40%","65%","100%"]

    const successChances = [100,100,100,100,100,40,30,20,10,5,0]

    const { web3Provider, connect, disconnect, address } = useWeb3Context()

    const [loaded, setLoaded] = React.useState(false)

    const [owner, setOwner] = React.useState(false)

    const [potion, setPotion] = React.useState(0)

    const [load, setLoad] = React.useState(false)

    const [level, setlevel] = React.useState(0)

    const [chance, setChance] = React.useState(0)

    const [eligible, setEligible] = React.useState(true)

    const [maxAmount, setMaxAmount] = React.useState(0)

    const [amount, setAmount] = React.useState(0);

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [mintVisible, setMintVisible] = React.useState(true);

    const countDownDate = new Date("2022-08-31T22:00:00.000-04:00").getTime();
    const [showModal, setShowModal] = React.useState(true);

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    const [bananaNft, setbananaNft] = React.useState([])

    const [leveling, setLeveling] = React.useState(false)

    const [failed, setFailed] = React.useState(false)

    const [success, setSuccess] = React.useState(false)

    const [progress, setProgress] = React.useState(false)

    const [progressRate, setProgressRate] = React.useState(0)

    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)


    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        //Runs only on the first render
        // if(web3Provider){
        //     loadBananaNft();
        // }else{
        //     navigate('/connect')
        // }
        loadBananaNft()
        //setOwner(true)
        getMetadata()
    }, [web3Provider])

    React.useEffect(async() => {
        if (progressRate === 100) {
            clearInterval(interval);
            let now = Math.floor(Date.now() / 1000)
            const body = {
                address: address,
                id: id,
                timestamp: now
            }
            const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

            const res = await axios.post(base_uri + '/levelUpSupremeBanana',
            body,
            {
            headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                hash_key: hashkey
            }
            })
            if(res.data.msg == "Success level up"){
                await getMetadata()
                setProgress(false)
                setSuccess(true)
            }else{
                await getMetadata()
                setProgress(false)
                setFailed(true)
            }
        }
        console.log("progressrate",progressRate)
    }, [progressRate]);
    

    const loadBananaNft = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(bananaContract, mutantbananaAbi, signer);
        const stakingcontract = new ethers.Contract(stakingContract, stakingAbi, signer);
        const ownerAddress = await contract.ownerOf(id)
        console.log("owner",ownerAddress)
        if(address.toLowerCase() == ownerAddress.toLowerCase()){
            setOwner(true)
        }else{
            const ownerToken = await stakingcontract.stakedNfts(address,1)

            console.log(ownerToken)
            ownerToken.map(
                (num) => {
                    if(num.toNumber() == id){
                        console.log("same",num.toNumber())
                        setOwner(true)
                    }
                }
            )
        }
    }

    const getMetadata = async() => {
        const res = await axios.get(base_uri+'/getLevelSupremeBanana?id='+id,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        let metadata = res.data
        let levels = metadata.level
        let chances = arrayCosts[levels]
        const resp = await axios.get(base_uri+'/getClaimedPrize?address='+ address +'&id='+2,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        let potions = resp.data.claimed
        console.log(resp.data)
        setPotion(resp.data.claimed)
        setlevel(levels)
        setChance(chances)
        console.log(chances)
        console.log(potions)
        if(chances > potions){
            console.log("chance")
            setEligible(false)
        }else{
            setEligible(true)
        }
    }

    const levelUp = async() => {
        
        const _id = toast.loading("Leveling in progress")
        try{
            let now = Math.floor(Date.now() / 1000)
            const body = {
                address: address,
                id: id,
                timestamp: now
            }
            const hashkey = enc.Hex.stringify(HmacSHA256(JSON.stringify(body),process.env.REACT_APP_SECRET_KEY))

            const res = await axios.post(base_uri + '/levelUp',
            body,
            {
            headers: {
                api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the token
                hash_key: hashkey
            }
            })
            
            if(res.data.msg == "Success level up"){
                toast.update(_id, {render: res.data.msg, type: "success", autoClose: 1000, isLoading:false});
                getMetadata();
                var element = document.getElementById("videobackground");
                element.classList.add("videogradient");
                setTimeout(() => {
                    element.classList.remove("videogradient")
                }, 5000);
            }else{
                toast.update(_id, {render: res.data.msg, type: "error", autoClose: 1000, isLoading:false});
                getMetadata();
                var element = document.getElementById("videobackground");
                element.classList.add("videofail");
                setTimeout(() => {
                    element.classList.remove("videofail")
                }, 5000);
            }

        }catch(err) {
            toast.update(_id, {render: "failure", type: "error", autoClose: 1000, isLoading:false});
        }
    }

    const levelingStart = async() =>{
        setProgressRate(0)
        setLeveling(true)
        setProgress(true)
        interval = setInterval(() => {
            setProgressRate((prev) => prev + 20);
        }, 500);
        
        //setSuccess(true)
        //setFailed(true)
    }


    return (
        <>
        <Preloader handleLoad={handleLoad} />
        <Header siteTitle={`Title`} />
        <div className='Mint__content'>
        
        {/* <motion.h1 
        {...paragraphAnimation}
        className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
    { !leveling &&
    <>
        <div className='level-container'>
            <p>UPGRADE NFT</p>
            <p style={{fontSize:"14px",marginTop:"15px"}}>Supreme Banana #{id}</p>
        
        <div className="level-change">
            { eligible &&
            <div className="level-before">
                {/* {"https://raffle.supremekong.com/images/"+object+".png"} */}
                {/* <video autoPlay loop playsInline controls muted src="https://assets.supremekong.com/mutant-bananas/images/mutant-banana.mp4" ></video> */}
                <img src={mutantbanana}></img>
                Level {level}
            </div>
            }
            { !eligible &&
            <div className="level-failed">
                {/* <video autoPlay loop playsInline controls muted src="https://assets.supremekong.com/mutant-bananas/images/mutant-banana.mp4" ></video> */}
                <img src={mutantbanana}></img>
                Level {level}
            </div>
            }
        </div>
        { eligible &&
            <div className="level-cost">
                <p>costs</p>
                <img src="https://assets.supremekong.com/images/marketplace/2.png"></img>
                <p>{potion}/{chance}</p>
            </div>
        }

        { !eligible &&
            <div className="level-cost-red">
                <p>costs</p>
                <img src="https://assets.supremekong.com/images/marketplace/2.png"></img>
                <p>{potion}/{chance}</p>
            </div>
        }


        <div className="level-cost">
            <p>Your chance of success is {successChances[level]}%</p>
        </div>


        <div className="level-button">
            { (owner && eligible) &&
                <div className='mintBtn' onClick={levelingStart}>
                    LEVEL UP
                </div>
            }

            { (!owner || !eligible) &&
                <div className='disableBtn'>
                    LEVEL UP
                </div>
            }
        </div>
        </div>
    </>
    }
    { leveling &&
    <>
        <div className='leveling-up-img'>
         { success &&
            <div className="level-border-success">
                <img src={mutantbanana}></img>
                <p>Level {level}</p>
            </div>
        }
        { failed &&
            <div className="level-border-fail">
                <img src={mutantbanana}></img>
                <p>Level {level}</p>
            </div>
        }
        { progress &&
            <div className="">
                <img src={mutantbanana}></img>
                <p>Level {level}</p>
            </div>
        }
        </div>
        <div className="leveling-up-txt">
        { progress &&
        <>
           LEVELING UP
        </>
        }
        { success &&
        <>
           <p style={{color:"#F2B90C"}}>
               LEVELING UP SUCCESS !
           </p>

           <p className="description">
               Your Supreme Bananas #{id} have been leveled up to Level {level}.
           </p>
           <p style={{color:"#F2B90C",fontSize:"20px"}}>
               Your yield bonus has increased to +{arrayBonus[level]} !
           </p>
        </>
        }
        { failed &&
        <>
           <p style={{color:"#C20000"}}>
               LEVELING UP FAILED !
           </p>

           <p className="description">
               Your Supreme Bananas #{id} have failed to level up.
           </p>
           <p style={{color:"#C20000",fontSize:"20px"}}>
               Your yield bonus has decreased to +{arrayBonus[level]} !
           </p>
        </>
        }
        </div>
        <div className="leveling-up-progress">
        { progress &&
            <ProgressBar bgcolor={"#F2B90C"} completed={progressRate} />
        }
        { ( success || failed ) &&    
            <div className='cancelBtn' onClick={()=>{
                setSuccess(false)
                setFailed(false)
                setLeveling(false)
            }}>
                CLOSE
            </div>
        }
        </div>
    </>
    }
      </div>
        </>
    );
}

export default Mint;
