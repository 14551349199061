import { motion } from 'framer-motion';
import React from 'react';
import { paragraphAnimation } from '../../utils/config';
import AnimateTextInView from '../lib/SectionTitle/AnimateTextInView';
import Tokenomic from '../../assets/images/htb.png'
import './About.scss';
import AboutSlider from './AboutSlider';
import uniImg from '../../assets/images/uni-logo.png'
import swapImg from '../../assets/images/swap-logo.png'
import ethImg from '../../assets/images/eth-logo.png'
import walletImg from '../../assets/images/wallet-logo.png'

function About() {
  return (
    <div id='about' className='About-jngl'>
        {/* <motion.h1 {...paragraphAnimation} className='About__title Title'>
          UNQUESTIONED DOMINANCE FINALLY UNLEASHED
        </motion.h1> */}
        <div style={{flexDirection:'column'}} className='flex fwrap fjc '>
          <div className='junglenomic-title'>
            How to buy
          </div>

          <div className='jungleins-table'>
            <div className='jungleins-tabletop'>
              <div className='jungleins-tabletop-left'>

                <div className='jungleins-tabletop-left-title'>
                  <img src={walletImg}></img>
                  Create a wallet
                </div>
                <div className='jungleins-tabletop-left-desc'>
                  Download Metamask or your wallet of choice from the app store or Google Play store for free. Desktop users, download the Google Chrome extension by going to metamask.io.
                </div>
                <div className='jungleins-lfg-btn'>
                    <a href='#'>
                      LFG!
                    </a>
                  </div>
              </div>
              <div className='jungleins-tabletop-right'>
                
                <div className='jungleins-tabletop-right-title'>
                  <img src={ethImg}></img>
                  Get some ETH
                </div>
                <div className='jungleins-tabletop-right-desc'>
                  Have ETH in your wallet to switch to $JNGL. If you don’t have ETH, you can buy directly on metamask, transfer from another wallet, or buy on an exchange and send it to your wallet.
                </div>
                <div className='jungleins-lfg-btn'>
                    <a href='#'>
                      LFG!
                    </a>
                  </div>
              </div>
            </div>
            <div className='jungleins-tablebottom'>
              <div className='jungleins-tablebottom-left'>
                <div className='jungleins-tablebottom-left-title'>
                  <img src={uniImg}></img>
                  Go to Uniswap
                </div>
                <div className='jungleins-tablebottom-left-desc'>
                  Go to app.uniswap.org. Connect your wallet. Paste the $JNGL token address into Uniswap, select $JNGL Token, and confirm. When Metamask prompts you for a wallet signature, sign.
                </div>
                  <div className='jungleins-lfg-btn'>
                    <a href='#'>
                      LFG!
                    </a>
                  </div>
              </div>
            <div className='jungleins-tablebottom-right'>
              <div className='jungleins-tablebottom-right-title'>
                  <img src={swapImg}></img>
                  Switch ETH for $JNGL
              </div>
              <div className='jungleins-tablebottom-right-desc'>
                Switch ETH for $JNGL. We have ZERO taxes so you don't need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.
              </div>
                <div className='jungleins-lfg-btn'>
                    <a href='#'>
                      LFG!
                    </a>
                </div>
            </div>
            </div>
          </div>
        </div>
      {/* <AboutSlider /> */}
    </div>
  );
}

export default About;
