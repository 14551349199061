import * as React from "react"
import './Mint.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/Header/Header';

function Mint() {

    const [loaded, setLoaded] = React.useState(false)

    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);


    return (
        <>
            <Preloader handleLoad={handleLoad} />
            <div className='modal-overlay'>
                <div className='modal-title'>
                <a href='https://opensea.io/collection/supremebananas'>
                    <DiscordButton onClick={ ()=>{}} href='' text='BUY ON OPENSEA' blank />
                </a>
                </div>
            </div>
        </>
    );
}

export default Mint;