import React, { useEffect, useState } from "react";
import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import leaves from '../../assets/icons/leaf-solid.svg'
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  customCell: {
    position: 'relative',
    borderBottom: 'solid 1px',
    color: '#F2B90C',
    fontFamily: 'Akira',
    overflowX: 'auto',
    '&:after': {
      content: '""',
      position: 'absolute',
      left: '10px',
      right: 0,
      bottom: 0,
      height: '10px', // You can adjust the height based on your needs
      backgroundRepeat: 'repeat-x',
      backgroundSize: '20px 10px', // Change '20px 10px' to the desired size
      backgroundPosition: '0 2px', // Change '0 2px' to adjust the starting position
    },
  },
  noBorder: {
    border: 'none !important',
    boxShadow: 'none !important',
  },
  customTableContainer: {
    border: 'none',
    boxShadow: 'none',
  },
  customTable: {
    backgroundColor: 'black',
    maxWidth: '100vw',
    border: 'none'
  },
  customPagination: {
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#F2B90C',
    },
    '& .MuiPaginationItem-page': {
      color: 'white',
    },
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
    backgroundColor: 'black',
  },
  pageInput: {
    width: '50px',
    '& .MuiInputBase-input': {
      color: '#F2B90C',
    },
    '& .MuiFormLabel-root': {
      color: '#F2B90C',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#F2B90C',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#F2B90C',
    },
  },
});

   const base_uri = "https://api.supremekong.com";
   //const base_uri = "http://localhost:8001";

const TransactionTable = ({ address }) => {
  const tokens = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${tokens}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100); // changed initial rows per page to 100
  const [manualPage, setManualPage] = useState('');

  const classes = useStyles();

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      // Convert it to hours
      const timezoneOffsetInHours = timezoneOffsetInMinutes / 60;
        const response = await axios.get(`${base_uri}/getUserTx/${address}`, {
          params: {
            timezoneOffset: timezoneOffsetInHours
          }
        });
        if (response.data.data && response.data.data.length > 0) {
          let data = response.data.data;
          setTransactions(data); // Ensure we only have 110 items at most
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    };

    getTransactions();
  }, [address]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleManualPageChange = (event) => {
    let value = parseInt(event.target.value, 10);
    setManualPage(value);
    if (value > 0 && value <= Math.ceil(transactions.length / rowsPerPage)) {
      setPage(value);
    }
  };

  return (
    <TableContainer component={Paper} className={classes.customTableContainer}>
      <Table className={classes.customTable}>
        <TableHead>
        </TableHead>
        <TableBody>
          {transactions
            .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
            .map((tx, index) => (
              <TableRow key={index}>
                <TableCell className={classes.customCell}>{tx}</TableCell>
              </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.paginationContainer}>
        <Pagination 
          count={Math.ceil(transactions.length / rowsPerPage)} 
          page={page} 
          onChange={handleChangePage} 
          className={classes.customPagination}
        />
        {/* <TextField 
          value={manualPage} 
          onChange={handleManualPageChange} 
          label="Go to page" 
          type="number"
          className={classes.pageInput}
        /> */}
      </div>
    </TableContainer>
  );
};

export default TransactionTable;
