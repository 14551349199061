import { motion } from 'framer-motion';
import React , {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import Header from '../../components/HeaderJngl/Header';


import etherscan from '../../assets/icons/etherscanIcon.png';
import discord from '../../assets/icons/discordIcon.png';
import opensea from '../../assets/icons/openseaIcon.png';
import twitter from '../../assets/icons/twitterIcon.png';
import dex from '../../assets/icons/dexIcon.png';
import emailIcon from '../../assets/icons/emailIcon.png';
import tg from '../../assets/icons/tgIcon.png';
import plus from '../../assets/images/plus.png'

import kongToken from '../../assets/images/kong-token.png'
import token2 from '../../assets/images/token-jngl.png'

import cartIcon from '../../assets/images/cart.png'
import trashIcon from '../../assets/images/trash-icon.png'

import './MP.scss';
import { useWeb3Context } from '../../context'

import { toast } from 'react-toastify';
import WalletConnectProvider from '@walletconnect/web3-provider'

const marketplaceAbi = require('../../helper/marketplace.json')
const alphaAbi = require('../../helper/alpha.json')

let web3Modal;

const base_uri = "https://api.supremekong.com";
//const base_uri = "http://localhost:8001";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
  },
}

if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
  })

  console.log(web3Modal)
}


export default function   Home() {


  const headerRef = useRef(null);

  const { web3Provider, connect, disconnect, address } = useWeb3Context()

  const [alphaBalance, setAlphaBalance] = React.useState(0)

  const marketplaceAddress = "0xEBe931eAA51E85FEaC14439478a36be5b18D61D2"

  // const [countDown, setCountDown] = React.useState(
  //   countDownDate - new Date().getTime()
  // ); 

  const [cart, setCart] = useState([]);

  const [cartError, setCartError] = useState('');

  const [Listing, setListing] = React.useState([]);

  const [updateBalance, setUpdateBalance] = useState(0);

  const [isMarketplacePage, setIsMarketplacePage] = useState(true);

  const [isCartVisible, setIsCartVisible] = useState(false);

  const handleRefreshBalance = () => {
    // Increment the updateBalance state to trigger the balance update in Header
    setUpdateBalance(updateBalance + 1);
  };

  useEffect(async() => {
    //Runs only on the first render
    if(web3Provider){ 
      console.log('web3Provider')
      //loadListing()
      //loadToken()
    }else{
    }
  }, [web3Provider]);

  const [selectedImage, setSelectedImage] = React.useState();

  const handleItemClick = (item) => {
    setSelectedImage(item.image); // You may need to adjust this line depending on your data structure
  };

  const loadListing = async() => {
    try{
      const res = await axios.get(base_uri+'/getJungleMarketplace',
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        let marketplaceData = res.data.result

        marketplaceData = marketplaceData.map((data)=> {
          data.amount = 0;
          return data;
        })
        
        setListing(marketplaceData)

    }catch(err){
      console.log(err)
    }
  }

  const max = (item) => {
    const updatedListing = Listing.map(listingItem => {
      if (listingItem === item) {
          return {
            ...listingItem,
            amount: item.supply,
          };
      }
      return listingItem;
    });
    setListing(updatedListing);
  };

  const increment = (item) => {
    const updatedListing = Listing.map(listingItem => {
      if (listingItem === item) {
        if (item.amount < item.supply) {
          return {
            ...listingItem,
            amount: item.amount + 1,
          };
        }
      }
      return listingItem;
    });
    setListing(updatedListing);
  };
  
  const decrement = (item) => {
    const updatedListing = Listing.map(listingItem => {
      if (listingItem === item) {
        if (item.amount > 0) {
          return {
            ...listingItem,
            amount: item.amount - 1,
          };
        }
      }
      return listingItem;
    });
    setListing(updatedListing);
  };
  
  
  
  

  const loadToken = async() => {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract("0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155", alphaAbi, signer);
    const balance = await contract.balanceOf(address);

    console.log(balance)
    
    const balanceInEther = ethers.utils.formatEther(balance);
    const roundedBalanceInEther = Math.ceil(balanceInEther);
    const formattedBalance = roundedBalanceInEther.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits:0});
    
    setAlphaBalance(formattedBalance);
  }

  // Add item to cart
  const addToCart = (itemToAdd) => {
    setCart((currentCart) => {
      const existingItem = currentCart.find((item) => item.id === itemToAdd.id);
      if (existingItem) {
        return currentCart.map((item) =>
          item.id === itemToAdd.id ? { ...item, quantity: item.quantity + 1 } : item
        );
      } else {
        return [...currentCart, { ...itemToAdd, quantity: 1 }];
      }
    });
  };

// Remove item from cart
const removeFromCart = (itemId) => {
  setCart((currentCart) => currentCart.filter((item) => item.id !== itemId));
};

// Clear the cart
const clearCart = () => {
  setCart([]);
};

useEffect(() => {
  // Clear the cart error when the cart updates
  setCartError('');
}, [cart]);

// Update item quantity in cart
const updateQuantity = (item, newQuantity) => {
  setCart((currentCart) =>
    currentCart.map((cartItem) =>
      cartItem.id === item.id
        ? { ...cartItem, quantity: newQuantity >= 0 ? newQuantity : 0 }
        : cartItem
    )
  );
};

// Batch buy items in cart
const buyBatch = async () => {
  if (!cart.length) {
    toast.error('Your cart is empty!');
    return;
  }

  // Replace with your actual contract interaction logic
  const signer = web3Provider?.getSigner();
  const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);
  const collectionIds = cart.map((item) => item.id);
  const quantities = cart.map((item) => item.quantity);

  // Logic to handle the purchase transaction
  // This is just a placeholder - you'll need to implement your actual transaction logic here
  try {
    // Dummy example of a contract call
    const tx = await contract.purchaseItems(collectionIds, quantities, /* other required params */);
    await tx.wait();
    toast.success('Transaction successful!');
    clearCart();
  } catch (error) {
    console.error('Transaction failed: ', error);
    toast.error('Transaction failed!');
  }
};



  const buy = async (item) => {
        console.log(item.id);
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);
        const tokenContract = new ethers.Contract("0xD6480C2c390A688F96eCF3C45F32C80b280B62c0", alphaAbi, signer);

        // Convert offChainPrice and offChainDiscount to Wei
        const priceInWei = ethers.utils.parseUnits(item.price.toString(), 18);
        const discountInWei = ethers.utils.parseUnits("0", 18);

        const totalPrice = priceInWei;

        // Check if approval is necessary
        const allowance = await tokenContract.allowance(address, marketplaceAddress);
        if (allowance.lt(totalPrice)) {
            toast.info('Requesting Approval');
            const approveTx = await tokenContract.approve(marketplaceAddress, totalPrice);
            await approveTx.wait();
            toast.success('Approval Successful');
        }

        // const signedData = await generateSignature(item);
        // Call to your backend for signature
        const nows = Math.floor(Date.now() / 1000);
        const bodys = {
            collectionId: item.id, 
            userAddress: address
        };


        const res = await axios.post(base_uri+'/signJungleMarketplace',
        bodys,
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', //the token is a variable which holds the tok
        }
        })

        console.log(res.data)


        //

        // const tx = await contract.buyItem(
        //     item.id, 
        //     "1", 
        //     priceInWei, 
        //     "1",  // Assuming offChainSupply is already in smallest unit
        //     discountInWei, 
        //     res.data.nonce,
        //     res.data.signature
        // );

        const tx = await contract.buyItem(
          res.data.item_id, 
          res.data.quantity,
          res.data.price_in_wei,
          "1", // Assuming offChainSupply is already in smallest unit
          res.data.discount_in_wei, 
          res.data.nonce,
          res.data.signature
      );

        await tx.wait();
        toast.success('Transaction Success');

        handleRefreshBalance();
        loadListing();
  };

  const toggleCartModal = () => {
    if (isMarketplacePage) {
        setIsCartVisible(!isCartVisible);
    }
  };


  const buyBatchMarketplace = async () => {
    if (cart.length === 0) {
      toast.error('Your cart is empty');
      return;
    }
  
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(marketplaceAddress, marketplaceAbi, signer);
  
    // Prepare data for batch operation
    const collectionIds = cart.map((item) => item.id);
    const quantities = cart.map((item) => item.quantity);
  
    // Calculate total price for approval (assuming the price is already stored in Wei)
    const totalPrices = cart.map((item, index) => ethers.utils.parseUnits(item.price.toString(), 18).mul(quantities[index]));
    const totalPrice = totalPrices.reduce((acc, price) => acc.add(price), ethers.constants.Zero);
  
    // Check if approval is necessary
    const tokenContract = new ethers.Contract("0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155", alphaAbi, signer); // Replace with your token contract address
    const allowance = await tokenContract.allowance(address, marketplaceAddress);
    if (allowance.lt(totalPrice)) {
      toast.info('Requesting Approval');
      const approveTx = await tokenContract.approve(marketplaceAddress, totalPrice);
      await approveTx.wait();
      toast.success('Approval Successful');
    }
  
    // Get signatures for batch items
    try {
      const response = await axios.post(`${base_uri}/signJungleMarketplaceBatch`, {
        collectionIds, 
        quantities,
        userAddress: address
      }, {
        headers: {
          api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m', // Replace with your actual API key
        }
      });
  
      const { data } = response;
  
      // Call the smart contract batch buy function
      const tx = await contract.buyItemsBatch(
        collectionIds,
        quantities,
        data.map(d => d.price_in_wei),
        quantities, // Assuming offChainSupply is already in smallest unit for each item
        data.map(d => d.discount_in_wei),
        data.map(d => d.nonce),
        data.map(d => d.signature)
      );
  
      await tx.wait();
      toast.success('Batch Transaction Success');
      handleRefreshBalance();
      clearCart(); // Clear the cart after successful purchase
    } catch (error) {

      let errorMsg = 'An error occurred while processing your request.';
      if (error.response && error.response.data && error.response.data.msg) {
        errorMsg = error.response.data.msg;
      }
      setCartError(errorMsg);
      console.error('Batch Transaction failed: ', error);
      toast.error('Batch Transaction failed!');
    }
  };
  


  return (
    <>
    <Header siteTitle={`Title`} updateBalance={updateBalance} showCartIcon={isMarketplacePage} onCartClick={toggleCartModal} ref={headerRef}/>
    <div
    id='Home' className='Marketplace'>
      <div className='Marketplace__content' style={{marginTop:'40px'}}>          
        <div className='marketplace-flex-container'>
          

          <div className='marketplace-banner-container'>
            <div style={{display:'flex'}}>
              <div className='marketplace-text-container-top'>
                  MARKETPLACE
              </div>
              {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                  Your balance : <img ></img> {alphaBalance}
              </div> */}
            </div>
            <div style={{display:'flex'}}>
              <div className='marketplace-text-container-bottom'>
                Hodl, stake, get rewarded
              </div>
              {/* <div className='marketplace-text-container' style={{marginLeft:'auto'}}>
                  Your balance : <img ></img> {alphaBalance}
              </div> */}
            </div>
            <div className='marketplace-banner-img'>
              <div className='opensea-txt'> The gamified ecosystem Powered by $JNGL </div>
              <a href='https://opensea.io/collection/supremekong' target="_blank"> <div className='opensea-btn'> Buy on Opensea </div></a>
            </div>
          </div>
          
          <div style={{display:'flex'}}>
            <div className='marketplace-text-container-bottom-title'>
              Available Rewards
            </div>
          </div>
          <div className='marketplace-items-container'>

          {/* { Listing.map( (item) => (
            <div className='marketplace-item-card' onClick={() => handleItemClick(item)}>
              <div className='marketplace-item-card-image'>
                <img src={item.image}></img>
                <div className="cart-icon-container" onClick={() => addToCart(item)}>
                  <img src={cartIcon} alt="Cart Icon" />
                </div>
              </div>

              <div className='marketplace-item-card-info'>

                <div className='marketplace-item-card-info-title'>
                  <p style={{fontSize:'20px'}}>{item.title}</p> 
                </div>

                <div className='marketplace-item-card-info-price'>
                  <p style={{fontSize:'14px'}}><img src={token2}></img> {item.price} $JNGL</p> <p style={{marginLeft:'auto'}}>Supply: {item.supply}</p>
                </div>

                
                <div className='marketplace-item-card-info-button'>
                    <div className='marketplace-item-card-info-button-buy' onClick={()=>{buy(item.itemId,item.price,item.amount)}}>
                      Buy
                    </div>

                    <div className='marketplace-item-card-info-button-minus' onClick={()=>{decrement(item)}}>
                      -
                    </div>
                    <div className='marketplace-item-card-info-button-amount' >
                      {item.amount}
                    </div> 
                    <div className='marketplace-item-card-info-button-plus' onClick={()=>{increment(item)}}>
                      +
                    </div>


                    <div className='marketplace-item-card-info-button-max' onClick={()=>{max(item)}}>
                      MAX
                    </div>

                </div>

              </div>
            </div>
          ))} */}

           
          </div>

        </div>
      </div>
    </div>
    {/* Cart Interface */}
      {/* Cart Interface */}
    { isCartVisible && (
    <div className='cart-container'>
      <h3>YOUR CART</h3>
      <div className='' style={{display:'flex',marginTop:'auto',marginBottom:'auto',gap:'20px'}}>
          <div className='top-balance' style={{display:'flex'}} data-tooltip-id="my-tooltip" data-tooltip-content="$JNGL">
            <a href='https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0x4c45bbec2ff7810ef4a77ad7bd4757c446fe4155' target='_blank'><img src={plus} style={{width:"40px",height:"40px"}}></img></a>
            <div className='balance-display' style={{fontSize:"20px"}}>{alphaBalance}</div> <img src={token2} width={40} height={40} ></img>
          </div>
        </div>
      {cart.length > 0 ? (
        <div>
          {cart.map((item, index) => (
            <div key={index} className='cart-item'>
              <div className='cart-item-checkbox'>
                {/* Implement checkbox if needed */}
              </div>
              <div className='cart-item-image'>
                <img src={item.image} alt={item.name} />
              </div>
              <div className='cart-item-details'>
                <span className='cart-item-title'>{item.title}</span>
              </div>
              <div className='cart-item-quantity'>
                  <button onClick={() => updateQuantity(item, item.quantity - 1)}>-</button>
                  <span>{item.quantity}</span>
                  <button onClick={() => updateQuantity(item, item.quantity + 1)}>+</button>
              </div>
              <div className='marketplace-item-card-info-price' style={{alignItems:'center'}}>
                  <p style={{fontSize:'14px'}}><img src={token2}></img> {item.price * item.quantity}</p> 
              </div>
              {/* <div className='cart-item-total-price'>
                <div className='marketplace-item-card-info-price' style={{alignItems:'center'}}>
                  <p style={{fontSize:'14px'}}><img src={token2}></img> {item.price * item.quantity}</p> 
                </div>
              </div> */}
              <button className='cart-item-delete' onClick={() => removeFromCart(item.id)}>
                <img src={trashIcon} width={20} height={20}></img>
              </button>
            </div>
          ))}
          <div className='cart-summary'>
            <div className='cart-total'>
            <div className='marketplace-item-card-info-price' style={{alignItems:'center'}}>
              <p className='cart-total-p' style={{fontSize:'14px'}}> Total : <img src={token2}></img>  {cart.reduce((total, item) => total + item.price * item.quantity, 0)} </p>
              {/* Total: $ */}
            </div>
            </div>

            {cartError && (
              <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>
                {cartError}
              </div>
            )}

            <div className='marketplace-item-card-info-button'>
            <div className='marketplace-item-card-info-button-buy-erc' onClick={buyBatchMarketplace}>
              Checkout
            </div>
            </div>
          </div>
        </div>
      ) : (
        <p style={{textAlign:'center'}}>Your cart is empty.</p>
      )}
      </div>
    )}
      {/* Footer */}
    <footer className="footer">
        <div className="footer__section">
          <p className="footer__copyright">Copyright © 2023 $JNGL Token. All Rights Reserved.</p>
        </div>
        <div className="footer__section"> 
         <a href='https://etherscan.io/token/0x4C45bbEc2fF7810ef4a77ad7BD4757C446Fe4155'><img className="footer__icon" src={etherscan} alt="Icon 1"/></a>
          <a href='https://discord.com/invite/supremekong'><img className="footer__icon" src={discord} alt="Icon 1"/></a>
          <a href='https://twitter.com/Supremekongnft'><img className="footer__icon" src={twitter} alt="Icon 1"/></a>
          <a href='https://opensea.io/collection/supremekong'> <img className="footer__icon" src={opensea} alt="Icon 1"/></a>
          <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xcd02a882804f3eb0f2d80f94a04defcea038359f'> <img className="footer__icon" src={dex} alt="Icon 1"/></a>
          <a href='mailto:cyrus@supremekong.com'> <img className="footer__icon" src={emailIcon} alt="Icon 1"/></a>
        </div>
    </footer>
    </>
  );
}
